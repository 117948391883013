import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userRedux";
import { resetCart } from "../../redux/cartRedux";

const LeftNav = styled.ul`
  padding: 0px;
  list-style: none;
  margin: 0px 10px 10px;
  text-align: left;
`;
const LeftNavItem = styled.li`
  padding-bottom: 8px;
  padding-left: 10px;
  border-bottom: 2px solid transparent;

  &:first-child {
    padding-left: 0px;
    font-size: 1.5rem;
  }
  &:first-child:disabled {
  }
  &:active {
    color: #6328e0;
  }
  &:last-child {
    color: red;
  }
`;
const LeftNavItemLink = styled(Link)`
  margin: 0px;
  padding: 0px;
  line-height: 22px;
  margin-right: 10px;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid transparent;
  text-decoration: none;
  color: inherit;
  &:hover {
    border-bottom: 2px solid #1a1a1a;
    transition: border 0.15s ease-in-out;
  }
`;
const LeftBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSignOutPressed = async () => {
    try {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          // dispatch({ type: "LOGOUT" });
          dispatch(logout());
          dispatch(resetCart());
          navigate("/");
        })
        .catch((error) => {
          // An error happened.
        });
    } catch (error) {
      console.log(error.message);
    }

  };
  
  return (
    <LeftNav>
      <LeftNavItem>
        <LeftNavItemLink to=".">Hesab məlumatları</LeftNavItemLink>
      </LeftNavItem>
      <LeftNavItem>
        <LeftNavItemLink to="personal" active>
          Şəxsi məlumatlar
        </LeftNavItemLink>
      </LeftNavItem>
      <LeftNavItem>
        <LeftNavItemLink to="orders">Sifarişlər</LeftNavItemLink>
      </LeftNavItem>
      <LeftNavItem>
        <LeftNavItemLink to="refund">Geri-qaytarılanlar</LeftNavItemLink>
      </LeftNavItem>
      {/* <LeftNavItem>
        <LeftNavItemLink to="sizes">Ölçülər</LeftNavItemLink>
      </LeftNavItem> */}
      <LeftNavItem>
        <LeftNavItemLink to="deletmyacount">Hesabım silinsin</LeftNavItemLink>
      </LeftNavItem>
      <LeftNavItem>
        <button onClick={onSignOutPressed}>Log out</button>
      </LeftNavItem>
    </LeftNav>
  );
};

export default LeftBar;
