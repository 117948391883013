import { createSlice } from "@reduxjs/toolkit";

let x = JSON.parse(localStorage.getItem("cart")) || [];

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: x.products || [],
    quantity: x.quantity || 0,
    total: x.total || 0,
  },
  reducers: {
    addProduct: (state, action) => {
      state.quantity += 1;
      state.products.push(action.payload?.product);
      state.total += Number(action.payload?.product.grossPrice);
      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeProduct: (state, action) => {
      state.products = state.products?.filter(
        (item) => item.itemSKU !== action.payload?.itemSKU
      );
      state.quantity -= 1;
      state.total -= Number(action.payload.grossPrice);
      localStorage.setItem("cart", JSON.stringify(state));
    },
    resetCart: (state) => {
      state.products = []
      state.quantity = 0;
      state.total = 0;
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

export const { addProduct, removeProduct,resetCart } = cartSlice.actions;
export default cartSlice.reducer;
