import {
  addDoc,
  collection,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { db } from "../../firebase";
import { removeProduct, resetCart } from "../../redux/cartRedux";
import uid from "../../utils/uid";
const Container = styled.div`
  padding: 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  flex-direction: column;
  background-color: #f3f3f3;
  min-height: 80vh;
  @media only screen and (max-width: 480px) {
    padding: 0px;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
  max-width: 80vw;
  margin: 24px auto;
  width: 100%;
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
    flex-direction: column;
  }
`;
const Left = styled.aside`
  flex-basis: 60%;
  background-color: #fff;
  @media only screen and (max-width: 480px) {
    flex-basis: 100%;
  }
`;
const Right = styled.section`
  flex-basis: 31.66667%;
  max-width: 31.66667%;
  margin-left: 7.33333%;
  background-color: #fff;
  @media only screen and (max-width: 480px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
`;
const ShoppingCart = styled.div`
  margin-bottom: 12px;
  padding: 22px;
  min-width: calc(100% - 150px);
`;
const ShoppingCartTitle = styled.h1`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 32px;
`;
const ShoppingCartList = styled.ul`
  padding: 0px;
  list-style: none;
`;
const ShoppingCartListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding: 24px 0px;
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;
`;
const ShoppingCartListItemImg = styled.img`
  max-width: 60px;
  margin-right: 20px;
  width: 100%;
`;
const ShoppingCartListItemSpan = styled.span`
  font-size: 12px;
`;
const ShoppingCartListDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: auto; */
`;
const ShoppingCartListItemBtn = styled.button`
  max-width: 50px;
  height: 100%;
`;
const ItemAddToCard = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  &:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 42, 47);
  }
`;
const ItemSmallNotice = styled.div`
  background-color: #efeff0;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 20px;
  font-size: 13px;
`;
const OrderCode = styled.span`
  font-size: 14px;
`;
const UidCode = styled.span`
  font-size: 1em;
  background: #ff6900;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  display: block;
  text-align: center;
`;

let today = new Date();
let date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
let time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
let dateTime = date + " " + time;
const Cart = () => {
  const current = new Date();
  current.setDate(current.getDate() + 12);
  let newDate = current.toDateString();
  const navigation = useNavigate();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { products, quantity, total } = useSelector((state) => state.cart);
  const handleDelete = (p) => {
    dispatch(removeProduct(p));
  };
  const handlePayment = async () => {
    const _order = {
      user: user.uid,
      orderData: {
        orderNumber: uid,
        paymentStatus: false,
        deliveryStatus: "",
        total: total?.toFixed(2),
        orderDate: dateTime,
        refund: false,
        refundDate: "",
      },
      orderItems: products,
      orderTracking: {
        [dateTime]: { paymentStatus: false, deliveryStatus: "" },
      },
    };
    try {
      console.log(_order);
      await addDoc(collection(db, "orders"), _order);
      dispatch(resetCart());
      navigation("/profile/personal");
    } catch (error) {
      console.log(error.code, error.message);
    }
  };

  return (
    <Container>
      <Main>
        <Left>
          <ShoppingCart>
            <ShoppingCartTitle>
              Səbətdə ({products?.length}) məhsul
            </ShoppingCartTitle>
            <ShoppingCartList>
              {products?.map((p) => (
                <ShoppingCartListItem key={p.catID}>
                  <ShoppingCartListDiv>
                    <ShoppingCartListItemImg src={p.mainImg} alt="" />
                  </ShoppingCartListDiv>
                  <ShoppingCartListDiv>
                    <ShoppingCartListItemSpan>
                      Brend: {p.brand}
                    </ShoppingCartListItemSpan>
                    <ShoppingCartListItemSpan>
                      {p.title && p.title}
                    </ShoppingCartListItemSpan>
                    <ShoppingCartListItemSpan>
                      Rəng: {p.colors}
                    </ShoppingCartListItemSpan>
                    <ShoppingCartListItemSpan>Sayı: 1</ShoppingCartListItemSpan>
                    <ShoppingCartListItemSpan>
                      Size: {p.size}
                    </ShoppingCartListItemSpan>
                    <ShoppingCartListItemSpan>
                      Qiymet: {p.grossPrice}
                    </ShoppingCartListItemSpan>
                  </ShoppingCartListDiv>
                  <ShoppingCartListDiv>
                    <ShoppingCartListItemBtn onClick={() => handleDelete(p)}>
                      Sil
                    </ShoppingCartListItemBtn>
                  </ShoppingCartListDiv>
                </ShoppingCartListItem>
              ))}
            </ShoppingCartList>
          </ShoppingCart>
        </Left>
        <Right>
          {quantity > 0 && (
            <ShoppingCart>
              <ShoppingCartTitle>
                <OrderCode>Sifariş Kodu:</OrderCode>

                <UidCode>{uid}</UidCode>
              </ShoppingCartTitle>

              <ShoppingCartList>
                <ShoppingCartListItem>
                  {total?.toFixed(2)}&nbsp;&#8380;
                </ShoppingCartListItem>
              </ShoppingCartList>
              <ShoppingCartListDiv>
                <ItemSmallNotice>
                  Təxmini çatdırılma tarixi : {newDate}
                </ItemSmallNotice>
                <ItemAddToCard onClick={handlePayment}>Ödə</ItemAddToCard>
              </ShoppingCartListDiv>
            </ShoppingCart>
          )}
        </Right>
      </Main>
    </Container>
  );
};

export default Cart;
