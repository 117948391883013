import React from "react";
import styled from "styled-components";

const FooterPage = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterPageContent = styled.div`
  width: 80vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
`;
const PageTitle = styled.h1``;
const FooterPageTextBlock = styled.div``;
const FooterPageTextContent = styled.p``;
const ReturnPolicy = () => {
  return (
    <FooterPage>
      <FooterPageContent>
        <PageTitle>Geri qaytarıma şərtləri</PageTitle>
        <FooterPageTextBlock>
          <FooterPageTextContent>
          
            <p>
              Sifarişi aldıqdan sonra məhsul(lar)dan narazı olarsınızsa
              məhsulun ƏDV xərci çıxılmaqla məhsulu geri qaytara bilərsiniz.
            </p>
            <p><b>Qeyd:</b>
               Məhsulun saytda qeyd olunmayan təsvir və məlumatlarından
              narazı olduğunuz halda geri qaytarılma etmək mümkündür.
            </p>

          </FooterPageTextContent>
        </FooterPageTextBlock>
      </FooterPageContent>
    </FooterPage>
  );
};

export default ReturnPolicy;
