import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { db } from '../../firebase';
const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
  @media only screen and (max-width: 480px) { font-size: 20px; line-height:22px;}

`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`;
const OrderListWrapper = styled.div`
  border-bottom: 1px solid rgb(221, 221, 221);
  padding-bottom: 30px;
  margin-bottom:30px ;
`;
const OrderListHead = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background: #e0e0e0;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;
const OrderListBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;

const OrderListItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;
const ItemSmallNotice = styled.div`
  background-color: #efeff0;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 20px;
  font-size: 13px;
`;
const OrderPaid = styled.span`
  color: #4caf50;
  text-align: center;
  font-weight: bold;
`;
const OrderStillUnpaid = styled.span`
  color: #f44336;
  text-align: center;
  font-weight: bold;
`;
const Refund = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    fetchOrders(user);
  }, [user]);
  const fetchOrders = async (user) => {
    let _posts = [];

    try {
      const citiesRef = collection(db, "orders");
      const q = query(citiesRef, where("user", "==", user.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        _posts.push({ id: doc.id, ...doc.data() });
        _posts = _posts.filter(post => post.orderData.refund === true)
        setOrders(_posts);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(orders[0])
  return (
    <ProfileWrapper>
    <ProfileWrapperTitle>Iadə olunan sifarişlər</ProfileWrapperTitle>
    <ProfilePersonal>
        {orders?.map((o) => (
          <OrderListWrapper key={o.id}>
            <OrderListHead>
              <OrderListItem title="Sifariş kodu" rowspan="2">
                {o.orderData.orderNumber}
              </OrderListItem>
              <OrderListItem title="Sifariş tarixi">
                {new Date(o.orderData.orderDate).toLocaleString()}
              </OrderListItem>
              <OrderListItem title="Iadə tarixi">
              {o?.orderData.refundDate}
              </OrderListItem>
              <OrderListItem title="Çatdırılma Statusu">
                {o?.orderData.refund === true && <b>Iadə edilib</b>}
              </OrderListItem>
              <OrderListItem title="Sifarişin cəm qiyməti">
                {o.orderData.total}&nbsp;&#8380;
              </OrderListItem>
            </OrderListHead>
          </OrderListWrapper>
        ))}
    </ProfilePersonal>
  </ProfileWrapper>  )
}

export default Refund