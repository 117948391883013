import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  background-color: #212a2f;
  color: #fff;
  position:relative;
`;
const Wrapper = styled.section`
  margin: 0px auto;
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  width: 80vw;
  @media only screen and (max-width: 480px) {
    padding: 16px;
    flex-direction: column;
    margin: 0px;
    width: 100%;
  }
`;
const Left = styled.div`
  flex: 2;
  display: flex;
`;
const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.h1`
  color: inherit;
`;
const Right = styled.div`
  flex: 2;
  display: flex;
  justify-content: right;
  align-items: center;
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  @media only screen and (max-width: 480px) {
    padding: 0px;
    flex-direction: column;
    margin: 0px;
    width: 100%;
  }
`;
const Menu = styled.ul`
  display: flex;
  @media only screen and (max-width: 480px) {
    padding: 0px;
    margin: 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const MenuItem = styled.li`
  list-style: none;
  margin-right: 10px;
  border-right: 1px solid #ccc;
  padding-right: 20px;
  font-size: 0.81rem;
  &:last-child {
    border: none;
  }
  @media only screen and (max-width: 480px) {
    padding-right: 10px;
    font-size: 12px;
  }
`;
const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const VersionInfo = styled.span`
position:absolute;
font-size:6px;
bottom:0px;
right:10px`
const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Nav>
            <Menu>
              <MenuItem>
                <MenuItemLink to={"/policy"}>İstifadəçi Şərtləri</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={"/returnPolicy"}>İadə Şərtləri</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={"/delivery"}>Çatdırılma</MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink to={"/payment"}>Ödəniş</MenuItemLink>
              </MenuItem>
            </Menu>
          </Nav>
        </Left>
        <Center>
          <Logo>BOGUCCI</Logo>
        </Center>
        <Right>
          <Nav>
            <Menu>
              <MenuItem>
                <MenuItemLink
                  to={"https://www.facebook.com/boguccifashion"}
                  target="_blank"
                >
                  Facebook
                </MenuItemLink>
              </MenuItem>
              <MenuItem>
                <MenuItemLink
                  to={"https://www.instagram.com/boguccifashion/"}
                  target="_blank"
                >
                  Instagram
                </MenuItemLink>
              </MenuItem>
            </Menu>
          </Nav>
        </Right>
      </Wrapper>
      <VersionInfo>v1.22.5az</VersionInfo>
    </Container>
  );
};

export default Footer;
