import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useSelector } from "react-redux";

const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
  @media only screen and (max-width: 480px) { font-size: 20px; line-height:22px;margin-bottom: 18px;}

`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`;

const OrderTableImg = styled.img`
  width: 50px;
  height: 80px;
  object-fit: cover;
  border-radius: 5%;
`;

const OrderListWrapper = styled.div`
  border-bottom: 1px solid rgb(221, 221, 221);
  padding-bottom: 30px;
  margin-bottom:30px ;
`;
const OrderListHead = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background: #e0e0e0;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;
const OrderListBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;

const OrderListItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;
const ItemSmallNotice = styled.div`
  background-color: #efeff0;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 20px;
  font-size: 13px;
`;
const OrderPaid = styled.span`
  color: #4caf50;
  text-align: center;
  font-weight: bold;
`;
const OrderStillUnpaid = styled.span`
  color: #f44336;
  text-align: center;
  font-weight: bold;
`;
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [unPaidCount, setUnPaidCount] = useState(null);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    fetchOrders(user);
  }, [user]);
  const fetchOrders = async (user) => {
    let _posts = [];

    try {
      const citiesRef = collection(db, "orders");
      const q = query(citiesRef, where("user", "==", user.uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        _posts.push({ id: doc.id, ...doc.data() });
        setOrders(_posts);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const findUnpaids = orders.filter(
      (x) => x.orderData.paymentStatus === false
    );
    let _count = findUnpaids.length;
    handleUserUnpaidOrders(_count);
    setUnPaidCount(_count);
  }, [orders]);

  const handleUserUnpaidOrders = async (findUnpaids) => {
    try {
      await updateDoc(doc(db, "users", user.uid), {
        unPaidCount: findUnpaids,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <ProfileWrapper>
      <ProfileWrapperTitle>Sifarişlər</ProfileWrapperTitle>
      <ItemSmallNotice>
        Ödənilməmiş <b>{unPaidCount} </b> sifarişiniz var
      </ItemSmallNotice>
      <ProfilePersonal>
        {orders?.map((o) => (
          <OrderListWrapper key={o.id}>
            <OrderListHead>
              <OrderListItem title="Sifariş kodu" rowspan="2">
                {o.orderData?.orderNumber}
              </OrderListItem>
              <OrderListItem title="Sifariş tarixi">
                {new Date(o.orderData.orderDate).toLocaleString()}

              </OrderListItem>
              <OrderListItem title="Ödəniş">
                {o.orderData?.paymentStatus ? (
                  <OrderPaid>Ödənilib</OrderPaid>
                ) : (
                  <OrderStillUnpaid>Ödənilməyib</OrderStillUnpaid>
                )}
              </OrderListItem>
              <OrderListItem title="Çatdırılma Statusu">
                {o.orderData?.deliveryStatus === "" ? (
                  <b>-</b>
                ) : (
                  <b>{o.orderData?.deliveryStatus}</b>
                )}
              </OrderListItem>
              <OrderListItem title="Sifarişin cəm qiyməti">
                {o.orderData?.total}&nbsp;&#8380;
              </OrderListItem>
            </OrderListHead>
            {o.orderItems?.map((i) => (
              <OrderListBody key={i.itemSKU}>
                <OrderListItem>
                  <OrderTableImg alt="Item Thumb" src={i.mainImg} />
                </OrderListItem>
                <OrderListItem>{i.brand}</OrderListItem>
                <OrderListItem>{i.colors}</OrderListItem>
                <OrderListItem>{i.size}</OrderListItem>
              </OrderListBody>
            ))}
          </OrderListWrapper>
        ))}
      </ProfilePersonal>
    </ProfileWrapper>
  );
};

export default Orders;
