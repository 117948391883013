import React, {useState } from "react";
import styled from "styled-components";
import loginbg from "../../assets/bg2.jpg";
import {
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, db, fb, provider } from "../../firebase";
import facebook from "../../assets/logo-Meta.png";
import google from "../../assets/Google__G__Logo.svg.png";

// import { AuthContext } from "../../contex/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import ErrorText from "../../componenets/ErrorText/ErrorText";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userRedux";

const LoginWrapper = styled.div`
  display: flex;
  background-image: url(${loginbg});
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
`;
const ButtonWrapper = styled.div`
  /* align-items: center;
  justify-content: center;
  padding: 27px 0px;
  flex: 1;
  display: flex;
  width: 100%; */
  /* padding: 20px 10px; */
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`;
const LoginGoogleIcon = styled.span`
  width: 25px;
  height: 100%;
  background-image: url(${google});
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
`;
const LoginFacebookIcon = styled.span`
  width: 30px;
  height: 100%;
  background-image: url(${facebook});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
`;
const LoginGoogleBtn = styled.button`
  max-width: 240px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  background: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: start;
  padding: 0px 10px;
`;
const LoginButtonText = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
const Login = () => {
  const [error, setError] = useState("");
  const [phone, setPhone] = useState(null);
  const [expandFOrm, setExpandForm] = useState(false);

  const [otp, setOtp] = useState("");
  const [showotp, setShowotp] = useState(false);
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  // const { dispatch } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Handle Facebook
  const handleLoginFacebook = async () => {
    try {
      signInWithPopup(auth, fb)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;

          // IdP data available using getAdditionalUserInfo(result)
          const { photoURL, displayName, uid } = user;
          checkUser(user);
          dispatch(login({ photoURL, displayName, uid, accessToken }));
          // dispatch({
          //   type: "LOGIN",
          //   payload: { photoURL, displayName, uid, accessToken },
          // });
          // handleUser(user);
        })
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          setError(errorCode);
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          console.log(errorMessage);
          // const credential = FacebookAuthProvider.credentialFromError(error);

          // ...
        });
    } catch (error) {}
  };

  //Handle Google
  const handleLoginGoogle = async () => {
    try {
      signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;
          const { photoURL, displayName, uid } = user;
          checkUser(user);
          dispatch(login({ photoURL, displayName, uid, token }));

          // dispatch({
          //   type: "LOGIN",
          //   payload: { photoURL, displayName, uid, token },
          // });

        })
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          setError(errorCode);
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    } catch (error) {}
  };

  //Check user
  const checkUser = async (user) => {
    const { uid } = user;
    try {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      console.log("Docsnap", docSnap.exists());

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          accessToken: user.stsTokenManager.accessToken,
          timeStamp: serverTimestamp(),
        });
        navigate("/");
      } else {
        await setDoc(doc(db, "users", uid), {
          name: user.displayName,
          picture: user.photoURL,
          email: user.email,
          accessToken: user.stsTokenManager.accessToken,
          author: user.uid,
          timeStamp: serverTimestamp(),
        });
        navigate("/");
      }
    } catch (error) {
      setError(error);
    }
  };
  const loginWithPhoneNumber = async (e) => {
    e.preventDefault();
    if (phone.length >= 12) {
      setExpandForm(true);
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setShowotp(true)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    try {
    } catch (error) {}
  };
  const verifyOtp = (e) => {
    let otp = e.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          const token = user.stsTokenManager.accessToken;
          const { photoURL, phoneNumber, uid } = user;
          dispatch({
            type: "LOGIN",
            payload: { photoURL, displayName:phoneNumber, uid, token  },
          });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
        });
    }
  };
  return (
    <LoginWrapper>
      <ButtonWrapper>
        <ErrorText props={error} />
        {/* <input type="tel" onChange={(e) => setPhone(e.target.value)} />
        {showotp&&<input type="number" onChange={verifyOtp} placeholder="otp" />}
        <button onClick={loginWithPhoneNumber}>Send code</button> */}
        <LoginGoogleBtn>
          <LoginGoogleIcon />
          <LoginButtonText onClick={handleLoginGoogle}>
            Login with Google
          </LoginButtonText>
        </LoginGoogleBtn>
        <LoginGoogleBtn>
          <LoginFacebookIcon />
          <LoginButtonText onClick={handleLoginFacebook}>
            Login with Facebook
          </LoginButtonText>
        </LoginGoogleBtn>
      </ButtonWrapper>
      <div id="recaptcha-container" className="recapture"></div>
    </LoginWrapper>
  );
};

export default Login;
