import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useSingle = (id) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchPost = async (id) => {
      const docRef = doc(db, "products", id);

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log(docSnap.data());
          setData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPost(id);
  }, [id]);
  return [data];
};

export default useSingle;
