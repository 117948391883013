import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

const UseMostWanted = () => {
  //catName is Category name ex: altcogory, mainCategory
  //catId is Category ex: Dresses

  // points.sort(function (a, b) {
  //   return a - b;
  // });
  const [mostWanted, setMostWanted] = useState(null);
  useEffect(() => {
    const fetchPost = async () => {
      let _posts = [];
      try {
        const citiesRef = collection(db, "products");
        const q = query(
          citiesRef,
          where("likes", ">", 0),
          orderBy("likes"),
          limit(4)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _posts.push({ id: doc.id, ...doc.data() });
          setMostWanted(_posts);
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchPost();
  }, []);

  return [mostWanted];
};

export default UseMostWanted;
