import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../../componenets/Card/Card";
import useFetch from "../../utils/UseFetch";
import UseMostWanted from "../../utils/UseMostWanted";
import useOnSale from "../../utils/useOnSale";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ItemSlider from "../../componenets/ItemSlider/ItemSlider";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Wrapper = styled.section``;
const Container = styled.div`
  max-width: 100vw;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 480px) {
    &[style] {
      width: 96vw !important;
      height: fit-content !important;
      flex-wrap:wrap;
      flex-direction: column;
    }
  }
`;
const HomeSlider = styled.div`
  display: flex;
  height: 90vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 120px;
  @media only screen and (max-width: 480px) {
    height: 45vh;
    margin-bottom: 40px;
  }
`;
const HomeSliderWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 50px;

  @media only screen and (max-width: 480px) {
    top: 30vh;
    left: 0px;
    margin: 15px;
  }
`;
const HomeSliderTitle = styled.h1`
  color: rgb(255, 255, 255);
  white-space: pre-line;
  font-size: 48px;
  line-height: 1.31;
  margin: 10px 0;
  @media only screen and (max-width: 480px) {
    font-size: 22px;
    line-height: 1.2;
  }
`;
const HomeSliderDescription = styled.p`
  color: rgb(255, 255, 255);
  margin: 12px 0px 0px;
  font-weight: 700;
  white-space: pre-line;
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin: 0px 0px 18px;
  padding: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
const HomeSliderBtn = styled(Link)`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 42, 47);
  border: 2px solid rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background-color: rgb(33, 42, 47);
    color: rgb(255, 255, 255);
    border: 2px solid rgb(33, 42, 47);
  }
  @media only screen and (max-width: 480px) {
    padding: 10px 15px;
  }
`;
const HomeFavWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin: 30px 0px 54px;
  .cartBox {
    max-width: 25%;
    position: relative;
    display: block;
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    .cartBox {
      flex: 50%;
      max-width: 100% !important ;
    }
  }
`;
const ContainerTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.32;
  letter-spacing: 0.5px;
  color: rgb(33, 42, 47);
  margin: 0px auto;
  padding: 0px;
`;
const Home = () => {
  const [mostWanted] = UseMostWanted();
  const [data] = useFetch("clothing", "subCategory");
  const [onsale] = useOnSale();
  const [containers, setContainers] = useState([]);
  const fashion = useSelector((state) => state.fashion.selectedFashion);

  useEffect(() => {
    const fetchContainers = async () => {
      try {
        // const q = query(
        //   collection(db, "containers"),
        //   where("wrapperType", "==", "sub")
        // );

        // const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //   console.log("Container", doc.id);
        //   alert(JSON.stringify(doc.data()));
        // });
        let _posts = [];

        const querySnapshot = await getDocs(collection(db, "containers"));
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          _posts.push({ id: doc.id, ...doc.data() });
          setContainers(_posts);
        });
      } catch (error) {
        console.log(error.code);
      }
    };
    fetchContainers();
  }, []);
  const _mainSlider = containers?.filter((x) => x["wrapperType"] === "main");
  const _lastMainSlider = _mainSlider[_mainSlider.length - 1];
  const _subSlider = containers?.filter((x) => x["wrapperType"] === "sub");
  const _subChill = _subSlider?.filter((x) => x["wrapperBackground"] !== "");
  const _subChillBrand = _subSlider?.filter((x) => x["wrapperBrand"] !== "");


  return (
    <Wrapper>
      <Container>
        <HomeSlider
          style={{
            backgroundImage: `url("${_lastMainSlider?.wrapperBackgroundImg}")`,
          }}
        >
          <HomeSliderWrapper>
            <HomeSliderTitle>{_lastMainSlider?.wrapperTitle}</HomeSliderTitle>
            <HomeSliderDescription>
              {_lastMainSlider?.wrapperDescription}
            </HomeSliderDescription>
            <HomeSliderBtn
              to={`/items/${fashion}/${_lastMainSlider?.qProps}`}
              state={{
                title: _lastMainSlider?.qValue,
                translate: _lastMainSlider?.itemHeading,
                desc: _lastMainSlider?.qValue,
                byWhat: _lastMainSlider?.qProps,
              }}
            >
              Shop now
            </HomeSliderBtn>
          </HomeSliderWrapper>
        </HomeSlider>
      </Container>
      <Container style={{ height: "100vh", width: "80vw" }}>
        <ContainerTitle>Most wanted</ContainerTitle>
        <HomeFavWrapper>
          {mostWanted?.map((mw) => (
            <Card key={mw.id} props={mw} />
          ))}
        </HomeFavWrapper>
      </Container>
      {_subChill &&
        _subChill.map((sl) => <ItemSlider props={sl} key={sl.id} />)}
    </Wrapper>
  );
};

export default Home;
