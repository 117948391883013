import React from "react";
import styled from "styled-components";

const FooterPage = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterPageContent = styled.div`
  width: 80vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
`;
const PageTitle = styled.h1``;
const FooterPageTextBlock = styled.div``;
const FooterPageTextContent = styled.p``;
const PaymentMethods = () => {
  return (
    <FooterPage>
      <FooterPageContent>
        <PageTitle>Ödəniş şərtləri və üsulları</PageTitle>
        <FooterPageTextBlock>
          <FooterPageTextContent>
          
            <b>Necə ödəniş edə bilərəm?:</b><br/> 1. Profil bölməsində bütün xanaları müvafiq olaraq doldurmaq lazimdir <br/>
            2. Məshsulu səbətə əlavə etdikdən
            sonra Səbət bölməsindən sifarişin kodunu qeyd edirik sonra card - to
            - card və ya başqa ödəmə sistimləri ilə sifariş kodunu və məbləği
            yazaraq ödəniş edirsiniz. Ödəniş təsdiqləndikdən sonra sifariş
            etdiyiniz məhsul(lar) sizə gödəriləcək.

          </FooterPageTextContent>
        </FooterPageTextBlock>
      </FooterPageContent>
    </FooterPage>
  );
};

export default PaymentMethods;
