import React from "react";
import styled from "styled-components";
const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: row;
`;
const ProfileContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ProfileEdit = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
`;
const ProfileLabel = styled.div`
  flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  /* max-width: 25%; */
  border: 1px solid #ddd;
  padding: 20px;
  /* min-width: 25%; */
  /* display: block; */
  /* float: left; */
  margin-right: 20px;
`;
const ProfileInfoLabel = styled.p`
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  &:nth-child(even) {
    background: red;
  }
`;
const ProfileInfoData = styled.p`
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  padding: 0px;
  margin: 0px 0px 24px;
`;
const Address = () => {
  return (
    <ProfileWrapper>
      <ProfileWrapperTitle>Ünvanlar</ProfileWrapperTitle>
      <ProfilePersonal>
        <ProfileContent>
          <ProfileLabel>
            <ProfileInfoData>
              Süleyman Sani Akhundov 3G, Binagady,Baku, AZ1000
            </ProfileInfoData>
            <ProfileEdit>Sil</ProfileEdit>
          </ProfileLabel>
        </ProfileContent>
        <ProfileLabel>
          <ProfileEdit>Yeni ünvan əlavə et</ProfileEdit>
        </ProfileLabel>
      </ProfilePersonal>
    </ProfileWrapper>
  );
};

export default Address;
