import { createSlice } from "@reduxjs/toolkit";

const favoriteSlice = createSlice({
  name: "favroite",
  initialState: {
    likes: JSON.parse(localStorage.getItem("likes")) || [],
  },
  reducers: {
    addLike: (state, action) => {
      state.likes.push(action.payload);
      localStorage.setItem("likes", JSON.stringify(state.likes));
    },
    removeLike: (state, action) => {
      state.likes = state.likes.filter(
        (item) => item.id !== action.payload.id
      );
      localStorage.setItem("likes", JSON.stringify(state.likes));
    },
  },
});
export const { addLike, removeLike } = favoriteSlice.actions;
export default favoriteSlice.reducer;
