import { useState, useEffect } from "react";
import { collection, query, where,getDocs } from "firebase/firestore";
import { db } from "../firebase";

const useFetch = (catID,catName) => {
  //catName is Category name ex: altcogory, mainCategory
  //catId is Category ex: Dresses
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchPost = async () => {
      let _posts = [];
      try {
        const citiesRef = collection(db, "products");
        const q = query(citiesRef, where(catName, "==", catID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _posts.push({ id: doc.id, ...doc.data() });
          setData(_posts)

        });
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchPost();
  }, [catID,catName]);




  return [data];
};

export default useFetch;