import { collection, getDocs, limit, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { db } from "../../firebase";
import Card from "../Card/Card";

const ItemSliderWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
  margin-top: 4rem;
  height: 100vh;

  @media only screen and (max-width: 480px) {
    height: auto;
  }
`;
const ItemSliderHeading = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: row;
  margin: 4rem auto 30px;
  position: relative;
  @media only screen and (max-width: 480px) {
    width: 96vw;
    flex-direction: column;
    margin: 1rem auto 1rem;
  }
`;
const ItemSliderBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin: 0px auto;
  .cartBox {
    max-width: 25%;
    position: relative;
    display: block;
  }
  @media only screen and (max-width: 480px) {
    width: 96vw;
    flex-wrap: wrap;
    .cartBox {
      flex: 50%;
      max-width: 100% !important ;
    }
  }
`;
const ItemSliderTitle = styled.h1`
  margin: 0px;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: -0.32px;
  line-height: 2.25rem;
  color: inherit;

  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;
const ItemSliderDesc = styled.p`
  margin: 0px;
  font-size: 2rem;
  letter-spacing: -0.32px;
  line-height: 2.25rem;
  color: inherit;

  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
`;
const ItemSliderLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3 1;
  @media only screen and (max-width: 480px) {
    margin: 0px 10px;
  }
`;
const ItemSliderRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 3;
`;
const ItemSliderLoadMoreBtn = styled(Link)`
  position: absolute;
  right: 0px;
  text-decoration: none;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;

  @media only screen and (max-width: 480px) {
    top: 8px;
    right: 16px;
    font-size: 12px;
  }
`;
const ItemSliderLoadMoreText = styled.span``;

const ItemSlider = ({ props }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const q = query(
          collection(db, "products"),
          where("isPublished", "==", true),
          where(props.qProps, "==", props.qValue),
          limit(props.qLimit)
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [props]);

  // const [fashion] = useContext(FashionContext);
  const fashion = useSelector((state) => state.fashion.selectedFashion);

  return (
    <ItemSliderWrapper
      style={{
        backgroundColor: props.wrapperBackgroundColor,
        color: props.wrapperTextColor,
      }}
    >
      <ItemSliderHeading>
        <ItemSliderLeft>
          <ItemSliderTitle>{props.wrapperTitle}</ItemSliderTitle>
          <ItemSliderDesc>{props.wrapperDescription}</ItemSliderDesc>
        </ItemSliderLeft>
        <ItemSliderRight>
          <ItemSliderLoadMoreBtn
            to={`/items/${fashion}/${props.qProps}`}
            state={{
              title: props.qValue,
              translate: props.itemHeading,
              desc: props.qValue,
              byWhat: props.qProps,
            }}
          >
            <ItemSliderLoadMoreText>Daha çox</ItemSliderLoadMoreText>
            <svg
              className="zds-icon RC794g X9n9TI DlJ4rT _5Yd-hZ UIFq6Y yzMMLf wu3klO"
              height="1em"
              width="1em"
              style={{ marginTop: 5, marginLeft: 8 }}
              focusable="false"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M.443 12c0 .414.336.75.75.75h20.869l-7.72 7.72a.75.75 0 0 0 1.06 1.06l7.94-7.94a2.252 2.252 0 0 0 0-3.18l-7.94-7.94a.75.75 0 0 0-1.06 1.06l7.72 7.72H1.192a.75.75 0 0 0-.75.75z"></path>
            </svg>
          </ItemSliderLoadMoreBtn>
        </ItemSliderRight>
      </ItemSliderHeading>
      <ItemSliderBody>
        {data?.slice(0, 4).map((d) => (
          <Card props={d} key={d.id} />
        ))}
      </ItemSliderBody>
    </ItemSliderWrapper>
  );
};

export default ItemSlider;
