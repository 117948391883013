import React from "react";
import styled from "styled-components";
import PersonalDetalis from "./PersonalDetalis";
import LeftBar from "../../componenets/LeftBar/LeftBar";
import { Link, useLocation } from "react-router-dom";
import Orders from "./Orders";
import Refund from "./Refund";
import Address from "./Address";
import MySizes from "./MySizes";
import DeleteMyAccount from "./DeleteMyAccount";
import Edit from "./Edit";
import { useSelector } from "react-redux";

const Container = styled.div`
  max-width: 80vw;
  margin: 24px auto;
  padding: 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
    margin: 0px auto;
    padding: 0px 0px;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
  min-height: 60vh;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Left = styled.aside`
  flex-basis: 30%;
  @media only screen and (max-width: 480px) {
    background: #009688;
    color: #fff;
    padding: 10px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    li {
      display: flex;
      width: 50%;
    }
    li button {
      width: 100%;
    }
  }
`;
const PaymentText = styled.p`
  background: #ffeb3b;
  font-size: 12px;
  padding: 8px;
`;

const Right = styled.section`
  flex-basis: 70%;
  max-width: 70%;
  margin-left: 10%;
  @media only screen and (max-width: 480px) {
    max-width: 100%;
    margin: 10px;
  }
`;
const HeadText = styled.h1`
  text-align: left;
  margin-top: 0px;
`;
const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const Profile = () => {
  let location = useLocation();
  let newloc = location.pathname.split("/")[2];
  const { user } = useSelector((state) => state.user);

  return (
    <Container>
      <Main>
        <Left>
          <LeftBar />
        </Left>
        <Right>
          {location.pathname.split("/")[1] === "profile" && (
            <>
              <HeadText>Salam, {user.displayName}</HeadText>
              <PaymentText>
                Sifariş etdiyiniz mallara sifarişlərim bölməsindən baxa
                bilərisiz. Sifarişlərinizi təsdiqlənməsi üçün sifariş nömrəsi(6 simvollu kod) və
                məbləği <pre>4169 7388 9035 2275</pre> hesab nömrəsinə ödədikdən
                sonra sifarişiniz təsdiqlənmiş sayılır. Sifarişinizin satusuna{" "}
                <b>Sifarişlər</b> bölməsindən baxa bilərisiz.
                <p>Sifarişinizi təsdiqləməzdən əvvəl <b>Şəxsi məlumatlar</b> bölməsini tam doldurun.</p>
                <br/>
                Əlaqə üçün:                <MenuItemLink
                  aria-label="Chat on WhatsApp"
                  to="https://wa.me/+48796234578"
                  target="_blank"
                  title="Chat on WhatsApp"
                >
                 <b>+48 796 234 578</b>
                </MenuItemLink>
              </PaymentText>
            </>
          )}
          {newloc === "personal" && <PersonalDetalis />}
          {newloc === "orders" && <Orders />}
          {newloc === "refund" && <Refund />}
          {newloc === "addresses" && <Address />}
          {newloc === "sizes" && <MySizes />}
          {newloc === "deletmyacount" && <DeleteMyAccount />}
          {newloc === "edit" && <Edit />}
        </Right>
      </Main>
    </Container>
  );
};

export default Profile;
