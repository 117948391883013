import { createSlice } from "@reduxjs/toolkit";

const fashionSlice = createSlice({
  name: "fashion",
  initialState: {
    selectedFashion: localStorage.getItem("fashion") || "Women",
  },
  reducers: {
    changeFashion: (state, action) => {
      state.selectedFashion = action.payload
      localStorage.setItem("fashion", state.selectedFashion);
    },
  },
});
export const { changeFashion } = fashionSlice.actions;
export default fashionSlice.reducer;
