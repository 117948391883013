import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Accordion from "../../componenets/Accordion/Accordion";
import OptionalWrapper from "../../componenets/OptionalWrapper/OptionalWrapper";
import { Link, useLocation } from "react-router-dom";
import useSingle from "../../utils/useSingle";
import { addProduct } from "../../redux/cartRedux";
import { useDispatch, useSelector } from "react-redux";
const Container = styled.div`
  max-width: 80vw;
  margin: 24px auto;
  padding: 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
    margin: 0px auto;
    padding: 0px 0px;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Left = styled.aside`
  flex-basis: 50%;
`;
const Right = styled.section`
  flex-basis: 41.66667%;
  max-width: 41.66667%;
  margin-left: 8.33333%;
  @media only screen and (max-width: 480px) {
    max-width: 100%;
    margin: 16px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
const MainImage = styled.div`
  flex: 5 1;
  display: block;
  overflow: hidden;
  /* cursor: zoom-in; */
  max-height: 90vh;
  @media only screen and (max-width: 480px) {
    order: 1;
  }
`;
const ImageThumbList = styled.ul`
  flex: 1 5;
  list-style: none;
  padding: 0px;
  margin: 0px 20px 0px 0px;
  height: 90vh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  @media only screen and (max-width: 480px) {
    order: 2;
    flex: 1;
    width: 100%;
    flex-direction: row;
    display: flex;
  }
`;
const ImageThumbItem = styled.li`
  margin-bottom: 0.5rem;
  border: 2px solid transparent;

  &::last-child {
    padding-bottom: 0px;
  }
  :hover {
    border: 2px solid #1a1a1a;
    cursor: pointer;
  }
`;
const ImageThumbImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;
const MainImageSrc = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
  :hover {
    /* transform: scale(5.5); */
  }
`;
const ItemConentWrapper = styled.div``;
const ItemBrand = styled(Link)`
  font-family: serif;
  font-size: 1.75rem;
  letter-spacing: -0.28px;
  line-height: 2rem;
  cursor: pointer;
  border: 2px solid transparent;
  text-decoration: none;
  font-weight: 500;
  font-family: "Lora", serif;

  color: inherit;
  &:hover {
    border-bottom: 2px solid #1a1a1a;
    transition: border 0.15s ease-in-out;
  }
`;
const ItemTitle = styled.h2`
  font-family: sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  letter-spacing: -0.28px;
  line-height: 2rem;
  margin: 5px auto;
`;
const ItemPriceWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
`;

// const ItemOnSale = styled.span``;
// // const ItemOnSalePrice = styled.span`
// //   font-size: 1.375rem;
// //   letter-spacing: -0.22px;
// //   line-height: 1.75rem;
// //   color: #d9000c;
// //   font-weight: 700;
// // `;

const ItemCurrentPrice = styled.p`
  font-size: 1.375rem;
  letter-spacing: -0.22px;
  line-height: 1.75rem;
  color: #1a1a1a;
  margin: 0px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: start;
  ${({ onSale }) =>
    onSale &&
    css`
      color: #d9000c;
      font-weight: 700;
    `}
`;
const ItemOnSale = styled.p`
  margin: 0px;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: #66676e;
`;
const ItemOnSalePrice = styled.span`
  text-decoration: line-through;
  color: #66676e;
  margin-left: 0.25rem;
`;
const ItemColor = styled.p`
  margin: 0px;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin-top: 1.5rem;
  font-weight: bold;
  margin-bottom: 3rem;

  margin-top: 2.25rem;
`;
const ItemSpan = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: #66676e;
  margin-left: 0.3rem;
  font-weight: normal;
`;
const ItemSize = styled.button`
  margin-right: 10px;
  padding: 6px 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 42, 47);
  border-radius: 3px;
  font-weight: 100;
  margin-bottom: 10px;
  cursor: pointer;
  border: 2px solid rgb(33,42,47);
  &:hover {
    color: rgb(33, 42, 47);
    background-color: rgb(211, 212, 213);
  }
  &:active {
    color: rgb(255, 255, 255);
    background-color: rgb(33, 42, 47);
  }
`;
const ItemSizeLabel = styled.span`
  font-size: 16px;
`;
const ItemSizeWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  &:focus {
    border: 1px solid #ddd;
  }
`;
const ItemAddToCard = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  &:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 42, 47);
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: rgb(33 42 47 / 21%);
    color: rgb(255, 255, 255);
  }
`;
const ItemSmallNotice = styled.div`
  background-color: #efeff0;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 20px;
  font-size: 13px;
`;
const ItemAccordion = styled.div`
  margin-top: 40px;
`;
const SuccessText = styled.span`
  text-align: center;
  width: 100%;
  display: block;
  font-size: 12px;
  color: green;
  margin-top: 10px;
`;
const Item = () => {
  let location = useLocation();
  let catID = location.pathname.split("/")[2];
  const dispatch = useDispatch();

  const [data] = useSingle(catID);
  const [mainImg, setMainImg] = useState();
  const onSale = false;
  const [isActive, setIsActive] = useState({ status: false, value: "" });
  const [duplicateItem, setDuplicateItem] = useState(false);
  //User notification
  const [warningText, setWaringtext] = useState("Səbətə əlavə edildi");
  const [actionDone, setActionDone] = useState(false);
  const fashion = useSelector((state) => state.fashion.selectedFashion);

  let quantity = 1;
  const { products } = useSelector((state) => state.cart);

  useEffect(() => {
    setMainImg(data?.images[0]);
    // setOrder({...order.size,img: mainImg, size: isActive?.value})
    setDuplicateItem(false);
    setIsActive({ status: false, value: "" })
  }, [data]);

  const handleCart = (product) => {
    //Check if item alread have
    const { brand, colors, grossPrice, itemSKU } = product;
    const _data = { mainImg, brand, colors, grossPrice, itemSKU };
    product = { ..._data, size: isActive.value, catID };
    let uniqueProducts = products.some((p) => p.itemSKU === product.itemSKU);
    if (uniqueProducts) {
      setDuplicateItem(true);
    } else {
      setActionDone(true);
      setTimeout(() => {
        setWaringtext("Səbətə əlavə edildi");
        setActionDone(false);
      }, 500);
      dispatch(addProduct({ product, quantity }));
      setDuplicateItem(true);
    }
  };
  // console.log("Comes from redux", products); && isActive.value !== ''
  const handleSize = (s) => {
    let _status = isActive.status;
    setIsActive({ status: _status, value: s });
  };
  return (
    <Container>
      <Main  aria-label={JSON.stringify(data?.tags)}>
        <Left>
          <ImageWrapper>
            <ImageThumbList>
            {data?.images.length > 0 ? data?.images.length > 0 &&
                data?.images.map((el) => (
                  <ImageThumbItem onMouseOver={() => setMainImg(el)} key={el}>
                    <ImageThumbImg
                      src={el.split("?")[0] + "?imwidth=156"}
                      alt=""
                    />
                  </ImageThumbItem>
                )): 'Şəkillər yülənir...'}
            </ImageThumbList>
            <MainImage>
              <MainImageSrc src={mainImg?.split("?")[0] + "?imwidth=762"} />
            </MainImage>
          </ImageWrapper>
        </Left>
        <Right>
          <ItemConentWrapper>
            <ItemBrand
             to={`/items/${fashion}/${'brand'}`}
                 state={{
                desc: data?.brand,
                byWhat: 'brand',
              }}>{data?.brand}</ItemBrand>
            <ItemTitle>{data?.title && data.title}</ItemTitle>
            <ItemPriceWrapper>
              <ItemCurrentPrice>
                {data?.grossPrice}&nbsp;&#8380;
                <ItemSpan>çatdırılma daxil</ItemSpan>
              </ItemCurrentPrice>
              {onSale && (
                <ItemOnSale>
                  Orginally:
                  <ItemOnSalePrice>39,99 &nbsp;&#8380;</ItemOnSalePrice>
                </ItemOnSale>
              )}
            </ItemPriceWrapper>
            <ItemColor>
              Rəng: <ItemSpan>{data?.colors}</ItemSpan>
            </ItemColor>
            <ItemSizeWrapper>
              {data?.sizes.length ? (
                data.sizes?.filter(item => !item.includes("Notify") && !item.includes("Only")).map((s) => (
                  <ItemSize
                    key={s}
                    className={isActive.value === s ? "bg-salmon" : ""}
                    onClick={() => handleSize(s)}
                  >
                    <ItemSizeLabel>{s}</ItemSizeLabel>
                  </ItemSize>
                ))
              ) : (
                <ItemSize>
                  <ItemSizeLabel
                    className={isActive.value === "onesize" ? "bg-salmon" : ""}
                    onClick={() => handleSize("onesize")}
                  >
                    Bir ölçü
                  </ItemSizeLabel>
                </ItemSize>
              )}
            </ItemSizeWrapper>
            {data?.itemnote && (
              <ItemSmallNotice>
                Ölçü kiçikdir, ona görə də bir ölçü böyük məsləhət görürük
              </ItemSmallNotice>
            )}
            <ItemAddToCard
              onClick={() => handleCart(data)}
              disabled={duplicateItem || isActive.value === ""}
            >
              Add to bag
            </ItemAddToCard>
            {actionDone ? <SuccessText>{warningText}</SuccessText> : ""}
            <ItemAccordion>
              <Accordion
                title="Material & Qulluq"
                content={data?.material}
                key="material"
              />
              <Accordion
                title="Ölçü və fit"
                content={data?.sizeAndFit}
                key="size"
              />
              <Accordion
                title="Detallar"
                content={data?.detalis}
                key="details"
              />
            </ItemAccordion>
          </ItemConentWrapper>
        </Right>
      </Main>
      <OptionalWrapper ff={data?.altCategory}/>
    </Container>
  );
};

export default Item;
