import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useFetch from "../../utils/UseFetch";
import Card from "../Card/Card";

const Container = styled.div`
  max-width: 100vw;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    &[style] {
      height: auto !important;
    }
  }
`;
const HomeFavWrapper = styled.section`
  display: flex;
  flex-direction: row;
  margin: 30px 0px 54px;
  .cartBox {
    position: relative;
    max-width: 25%;
  }

  @media only screen and (max-width: 480px) {
    &[style] {
      width: 96vw !important;
      flex-wrap: wrap;
    }
    .cartBox {
      flex: 50%;
      max-width: 100% !important ;
      position: relative;
    }
  }
`;
// const ContainerTitle = styled.h1`
//   font-size: 32px;
//   font-weight: 700;
//   line-height: 1.32;
//   letter-spacing: 0.5px;
//   color: rgb(33, 42, 47);
//   margin: 0px auto;
//   padding: 0px;
// `;
const OptionalWrappers = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
  margin-top: 4rem;
  @media only screen and (max-width: 480px) {
    width: 96vw !important;
    flex-direction: column;
    margin: 1rem auto 1rem;
  }
`;
const OptionalWrapperTitle = styled.h1`
  margin: 0px;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: -0.32px;
  line-height: 2.25rem;
  color: inherit;
  font-family: inherit;
  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;
const OptionalWrapperDesc = styled.p`
  margin: 0px;
  font-size: 2rem;
  letter-spacing: -0.32px;
  line-height: 2.25rem;
  color: inherit;
  font-family: "Lora", serif;
  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
`;
const OptionalWrapperLoadMore = styled(Link)`
  position: absolute;
  right: 0px;
  text-decoration: none;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    top: 8px;
    right: 16px;
    font-size: 12px;
  }
`;
const GetMoreText = styled.span``;
const OptionalWrapper = ({ ff }) => {
  const [data] = useFetch(ff, "altCategory");
  return (
    <Container style={{ height: "100vh", color: "rgb(33,42,47)" }}>
      <OptionalWrappers>
        <OptionalWrapperTitle>Oxşar məhsullar</OptionalWrapperTitle>
        <OptionalWrapperDesc>
          Hmm... Bəyənmədinsə bunlara bax
        </OptionalWrapperDesc>
        <OptionalWrapperLoadMore to={`/items/${ff}`}>
          <GetMoreText>Daha çox</GetMoreText>
          <svg
            className="zds-icon RC794g X9n9TI DlJ4rT _5Yd-hZ UIFq6Y yzMMLf wu3klO"
            height="1em"
            width="1em"
            style={{ marginTop: 5, marginLeft: 8 }}
            focusable="false"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M.443 12c0 .414.336.75.75.75h20.869l-7.72 7.72a.75.75 0 0 0 1.06 1.06l7.94-7.94a2.252 2.252 0 0 0 0-3.18l-7.94-7.94a.75.75 0 0 0-1.06 1.06l7.72 7.72H1.192a.75.75 0 0 0-.75.75z"></path>
          </svg>
        </OptionalWrapperLoadMore>
      </OptionalWrappers>
      <HomeFavWrapper style={{ width: "80vw", margin: "0px auto" }}>
        {data
          ?.filter((item, idx) => idx < 4)
          .map((d) => (
            <Card props={d} key={d.id} />
          ))}
      </HomeFavWrapper>
    </Container>
  );
};

export default OptionalWrapper;
