import { createSlice } from "@reduxjs/toolkit";

const userSlicer = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    logout: (state, action) => {
      state.user = null;
      localStorage.setItem("user", null);
      // localStorage.setItem("cart", []);
      // localStorage.setItem("likes", []);
      // window.location.href = window.location.origin + "/";
    },
    update: (state, action) => {
      let _newData = action.payload;
      state.user = { ...state.user, _newData };
      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
});
export const { login, logout, update } = userSlicer.actions;
export default userSlicer.reducer;
