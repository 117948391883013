import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  // orderBy,
  // limit,
} from "firebase/firestore";
import { db } from "../firebase";

const useOnSale = () => {
  //catName is Category name ex: altcogory, mainCategory
  //catId is Category ex: Dresses

  // points.sort(function (a, b) {
  //   return a - b;
  // });
  const [onsale, setOnSale] = useState(null);
  // function randomNumber(min, max) {
  //   return Math.random() * (max - min) + min;
  // }
  useEffect(() => {
    const fetchPost = async () => {
      let _posts = [];
      try {
        const citiesRef = collection(db, "products");
        const q = query(citiesRef, where("onSale", "==", true));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _posts.push({ id: doc.id, ...doc.data() });
          setOnSale(_posts);
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchPost();
  }, []);

  return [onsale];
};

export default useOnSale;
