import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Card from "../../componenets/Card/Card";
import { useLocation, Link } from "react-router-dom";
// import useFetch from "../../utils/UseFetch";
// import { useFashion } from "../../contex/FashionContext";
import useFindCat from "../../utils/useFindCat";
import { useSelector } from "react-redux";
const Container = styled.div`
  max-width: 80vw;
  margin: 0px auto;
  padding: 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
    margin: 0px auto;
    padding: 0px 15px;
  }
`;
const Breadcrumbs = styled.div``;
const BreadcrumbsList = styled.ul`
  display: flex;
  margin: 20px 10px 10px;
  padding: 0px;
  justify-content: start;
  align-items: start;
  width: fit-content;
`;
const BreadcrumbsListItem = styled.li`
  display: flex;
  /* align-items: center; */
  justify-content: start;
  flex: 1;
  margin-right: 10px;
  font-weight: 400;
  &:last-child {
    font-weight: 100;
  }
`;
const BreadcrumbsListItemLink = styled.a`
  margin: 0px;
  padding: 0px;
  line-height: 11px;
  margin-right: 10px;
`;
const PageTitle = styled.h1`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  color: #1a1a1a;
  margin: 20px 10px 10px;
  display: flex;

  @media only screen and (max-width: 480px) {
    font-size: 2em;
    letter-spacing: -0.4px;
    line-height: 2.2rem;
    margin: 0px;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
`;
const Left = styled.aside`
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
// const Right = styled.section`
//   flex: 3 1;
//   width: 100%;
// `;
const LeftNav = styled.ul`
  padding: 0px;
  list-style: none;
  margin: 20px 10px 10px;
  flex: 1;
  @media only screen and (max-width: 480px) {
    width: 50%;
    flex: 1;
  }
`;
const LeftNavItem = styled.li`
  padding-bottom: 8px;
  padding-left: 10px;
  border-bottom: 2px solid transparent;

  &:first-child {
    padding-left: 0px;
    font-size: 1.5rem;
    color: #6328e0;
  }
  &:first-child:disabled {
  }
  &::active {
  }
`;
const LeftNavItemLink = styled(Link)`
  margin: 0px;
  padding: 0px;
  line-height: 22px;
  margin-right: 10px;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid transparent;
  text-decoration: none;
  color: inherit;
  &:hover {
    border-bottom: 2px solid #1a1a1a;
    transition: border 0.15s ease-in-out;
  }
`;
// const RightItemWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;
// const Item = styled.div`
//   flex-basis: 25%;
//   max-width: 25%;
//   flex-grow: 0;
//   flex-shrink: 0;
//   padding: 20px 0 24px;
// `;
// const RightFilterWrapper = styled.div``;

const Items = () => {
  let location = useLocation();
  let str = location.pathname.split("/")[2];
  let state = location.state;
  let catID = str.charAt(0).toUpperCase() + str.slice(1);
  let catName = "altCategory";
  let mainCat = location.state?.uri;
  let subCat = "Shoes";
  const [datas] = useFindCat(mainCat, subCat);

  // const [data] = useFetch(catID, catName);
  // const fashion = useFashion();
  const fashion = useSelector((state) => state.fashion.selectedFashion);
  let _subcatClothing = datas?.filter((x) => x.subCategory === "Clothing");
  let _subcatShoes = datas?.filter((x) => x.subCategory === "Shoes");
  let _subcatAccessories = datas?.filter(
    (x) => x.subCategory === "Accessories"
  );
  let _subcatBandCare =
    mainCat === "Women"
      ? datas?.filter((x) => x.subCategory === "Beauty")
      : datas?.filter((x) => x.subCategory === "Care");
  return (
    <Container>
      <Breadcrumbs>
        <BreadcrumbsList>
          <BreadcrumbsListItem>
            <BreadcrumbsListItemLink>{state?.title}</BreadcrumbsListItemLink>
            <svg
              style={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                letterSpacing: "-.16px",
                lineHeight: "1.5rem",
              }}
              focusable="false"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-labelledby="chevron-right-41132"
              aria-hidden="true"
            >
              <path d="M7.63 21.14a.75.75 0 0 1 0-1.06l7.55-7.55a.751.751 0 0 0 0-1.06L7.63 3.92a.75.75 0 1 1 1.06-1.061l7.55 7.55a2.252 2.252 0 0 1 0 3.182l-7.55 7.55a.748.748 0 0 1-1.06 0z"></path>
            </svg>
          </BreadcrumbsListItem>
          <BreadcrumbsListItem>
            <BreadcrumbsListItemLink>{state?.title}</BreadcrumbsListItemLink>
          </BreadcrumbsListItem>
        </BreadcrumbsList>
      </Breadcrumbs>
      <PageTitle>{state && state.title}</PageTitle>
      <Main>
        <Left>
          <LeftNav>
            <LeftNavItem>
              <LeftNavItemLink active style={{ pointerEvents: "none" }}>
                Geyim
              </LeftNavItemLink>
            </LeftNavItem>
            {_subcatClothing?.map((x) => (
              <LeftNavItem key={x.uri}>
                <LeftNavItemLink
                  to={`${x.uri}`}
                  state={{
                    title: x.title,
                    translate: x.translate,
                    desc: "Geyim",
                    byWhat: "altCategory",
                  }}
                >
                  {x.translate}
                </LeftNavItemLink>
              </LeftNavItem>
            ))}
          </LeftNav>
          {_subcatShoes?.length > 0 && <LeftNav>
            <LeftNavItem>
              <LeftNavItemLink active style={{ pointerEvents: "none" }}>
                Ayaqqabı
              </LeftNavItemLink>
            </LeftNavItem>
            {_subcatShoes?.map((x) => (
              <LeftNavItem key={x.uri}>
                <LeftNavItemLink
                  to={`${x.uri}`}
                  state={{
                    title: x.title,
                    translate: x.translate,
                    desc: "Ayaqqabı",
                    byWhat: "altCategory",
                  }}
                >
                  {x.translate}
                </LeftNavItemLink>
              </LeftNavItem>
            ))}
          </LeftNav>}
          {_subcatBandCare?.length > 0 && (
            <LeftNav>
              <LeftNavItem>
                <LeftNavItemLink active style={{ pointerEvents: "none" }}>
                  Baxım & Qulluq
                </LeftNavItemLink>
              </LeftNavItem>
              {_subcatBandCare?.map((x) => (
                <LeftNavItem key={x.uri}>
                  <LeftNavItemLink
                    to={`${x.uri}`}
                    state={{
                      title: x.title,
                      translate: x.translate,
                      desc: "Baxım",
                      byWhat: "altCategory",
                      // byWhat: props.qProps
                    }}
                  >
                    {x.translate}
                  </LeftNavItemLink>
                </LeftNavItem>
              ))}
            </LeftNav>
          )}
          {_subcatAccessories?.length > 0 && (
            <LeftNav>
              <LeftNavItem>
                <LeftNavItemLink active style={{ pointerEvents: "none" }}>
                  Aksesuar
                </LeftNavItemLink>
              </LeftNavItem>
              {_subcatAccessories?.map((x) => (
                <LeftNavItem key={x.uri}>
                  <LeftNavItemLink
                    to={`${x.uri}`}
                    state={{
                      title: x.title,
                      translate: x.translate,
                      desc: "Aksesuarlar",
                      byWhat: "altCategory",
                    }}
                  >
                    {x.translate}
                  </LeftNavItemLink>
                </LeftNavItem>
              ))}
            </LeftNav>
          )}
        </Left>
      </Main>
    </Container>
  );
};

export default Items;
