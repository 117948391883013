import React, { useState } from "react";
import styled, { css } from "styled-components";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addLike, removeLike } from "../../redux/favoriteRedux";
const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  max-width: 25%;
`;
const CardBody = styled.div`
  display: flex;
  overflow: hidden;
  margin: 0px 8px;
  flex-direction: column;
  position: relative;
  /* &:fc{
    margin-left:0px ;
  } */
`;
const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 420px;
  @media only screen and (max-width: 480px) {
    min-height: 30vh;
  }
`;
const CardFooter = styled.div`
  padding-top: 0.5rem;
`;
const CardItemBrand = styled.h5`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
`;
const CardItemTitle = styled.p`
  font-weight: 400;
  line-height: 1.43;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  display: block;
  margin: 0px;
  margin-bottom: 0.5rem;
  color: inherit;
`;
const CardItemPrice = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin: 0px;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.onSale &&
    css`
      color: #d9000c;
      font-weight: 700;
    `}
`;
const CardItemSale = styled.p`
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1rem;
  margin: 0px;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
`;
const CardItemDiscountPrice = styled.span`
  text-decoration: line-through;
`;
const AddToFavorite = styled.button`
  position: absolute;
  top: 70%;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: #ffffffe6;
  font-size: 1.2rem;
  border: 0px;
  border-radius: 50%;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  cursor: pointer;
  z-index: 999;
  text-decoration: none;
  display: block;
  color: #222;
  -webkit-appearance: none
  &:hover {
    background-color: rgb(255, 105, 0);
    color: #fff;
  }
`;
const Card = ({ props }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  // const [favorites, setFavorites] = useState([]);
  // const [item, setItem] = useState({});
  // const handleLike = (e) => {
  //   let likes = e.target.id;
  //   console.log(likes)
  //   dispatch({ type: "LIKE", payload: { likes }, });
  // };

  // useEffect(() => {
  //   fetchItem();
  // }, []);

  // const fetchItem = async () => {
  //   const docRef = doc(db, "products", "dwGGvHptWGhzqyKRCl6a");
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     const { price, profit, deliveryFee, externalUrl, ...rest } =
  //       docSnap.data();
  //     // console.log("Document data:", rest);
  //     setItem(rest);
  //   } else {
  //     // doc.data() will be undefined in this case
  //     console.log("No such document!");
  //   }
  // };
  // const { itemSKU } = item;
  let itemMainImage = props?.images[0].replace("?imwidth=156&", "");
  itemMainImage = itemMainImage.replace("filter=packshot", "");
  const dispatch = useDispatch();

  const handleToogleLike = async () => {
    let _item = {
      id: props?.id,
      brand: props?.brand,
      itemSKU: props?.itemSKU,
      image: itemMainImage,
      color: props?.colors,
    };
    if (isFavorite === false) {
      setIsFavorite(!isFavorite);
      dispatch(addLike(_item));
    } else {
      setIsFavorite(!isFavorite);
      dispatch(removeLike(_item));
    }
  };

  return (
    <div className="cartBox">
      <AddToFavorite
        onClick={() => handleToogleLike(props)}
        className={`${isFavorite && "isFavorite"}`}
      >
        +
      </AddToFavorite>
      <CardLink to={`/item/${props?.id}`} key={props?.id}>
        <CardBody>
          <CardImg src={itemMainImage} alt={props?.brand} draggable="false" />
          <CardFooter>
            <CardItemBrand>{props?.brand}</CardItemBrand>
            <CardItemTitle>{props?.title}</CardItemTitle>
            <CardItemPrice>{props?.grossPrice} &nbsp;&#8380;</CardItemPrice>
            {props?.onSale && (
              <CardItemSale>
                Əvvəlki:
                <CardItemDiscountPrice>
                  {props?.onSalePrice && props.onSalePrice} &nbsp;&#8380;
                </CardItemDiscountPrice>
              </CardItemSale>
            )}
          </CardFooter>
        </CardBody>
      </CardLink>
    </div>
  );
};

export default Card;
