import React from "react";
import styled from "styled-components";
const FooterPage = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterPageContent = styled.div`
  width: 80vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
`;
const PageTitle = styled.h1``;
const FooterPageTextBlock = styled.div``;
const FooterPageTextContent = styled.p``;
const OrderTracking = () => {
  return (
    <FooterPage>
      <FooterPageContent>
        <PageTitle>Çatdırılma şərtləri</PageTitle>
        <FooterPageTextBlock>
          <FooterPageTextContent>
            <b>Standart Çatdırılma:</b> Bütün sifarişlər ödəniş edildikdən sonra
            standart olaraq pulsuz çatırılır çatdırılma sifarişlərinizi
            tamamlayarkən göstərilir. Lakin çatdırılma sifarişin ödənişi təsdiq
            olunadan sonra yola salınır və 12 iş günü ərzində çatrırılır(Bayram
            və qeyri iş günləri çıxmaqla). Sifarişınızın çatrılma mərhələrin 
            <code>  HESAB / SİFARİŞLƏR  </code> bölməsindən izləyə biləriniz.
            Əlavə suallar üçün bizimlə əlaqə saxlayın.
          </FooterPageTextContent>
        </FooterPageTextBlock>
      </FooterPageContent>
    </FooterPage>
  );
};

export default OrderTracking;
