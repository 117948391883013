import { useContext, useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import { Navigate, Routes } from "react-router";
// import { AuthContext } from "./contex/AuthContext";

import Home from "./pages/Home/Home";
import { Header } from "./componenets/Header/Header";
import Footer from "./componenets/Footer/Footer";
import Items from "./pages/Items/Items";
import Item from "./pages/Item/Item";
import Profile from "./pages/Profile/Profile.jsx";
import Login from "./pages/Auth/Login";
import Orders from "./pages/Profile/Orders";
import PersonalDetalis from "./pages/Profile/PersonalDetalis";
import Refund from "./pages/Profile/Refund";
import Address from "./pages/Profile/Address";
import Sizes from "./pages/Profile/MySizes";
import DeleteMyAccount from "./pages/Profile/DeleteMyAccount";
import Cart from "./componenets/Cart/Cart";
import Edit from "./pages/Profile/Edit";
import Subitems from "./pages/Items/Subitems";
import WishList from "./pages/WishList/WishList";
import { useDispatch, useSelector } from "react-redux";
import { resetCart } from "./redux/cartRedux";
import TermofCondition from "./pages/Footer/TermofCondition";
import OrderTracking from "./pages/Footer/OrderTracking";
import ReturnPolicy from "./pages/Footer/ReturnPolicy";
import PaymentMethods from "./pages/Footer/PaymentMethods";

console.log(
  "%cBOGUCCI",
  "color: #1a1a1a; font-size: 40px; font-family:'inherit'"
);
function App() {
  const { user } = useSelector((state) => state.user);
  const RequireAuth = ({ children }) => {
    return user ? children : <Navigate to="/login" />;
  };
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(resetCart());

  //   return () => {
  //     dispatch(resetCart());
  //   };
  // }, [user]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          >
            <Route
              path="personal"
              element={
                <RequireAuth>
                  <PersonalDetalis />
                </RequireAuth>
              }
            />
            <Route
              path="orders"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
            <Route
              path="refund"
              element={
                <RequireAuth>
                  <Refund />
                </RequireAuth>
              }
            />
            <Route
              path="addresses"
              element={
                <RequireAuth>
                  <Address />
                </RequireAuth>
              }
            />
            <Route
              path="sizes"
              element={
                <RequireAuth>
                  <Sizes />
                </RequireAuth>
              }
            />
            <Route
              path="deletmyacount"
              element={
                <RequireAuth>
                  <DeleteMyAccount />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/edit"
              element={
                <RequireAuth>
                  <Edit />
                </RequireAuth>
              }
            />
          </Route>
          <Route index element={<Home />} />
          <Route path="login" element={!user ? <Login /> : <Home />} />
          <Route path="/wishlist" element={!user ? <Login /> : <WishList />} />
          <Route path="/basket" element={!user ? <Login /> : <Cart />} />
          <Route
            path="items/:id"
            element={<Items />}
            state={{ title: "Women’s Clothing" }}
          />
          <Route path="items/:id/:id" element={<Subitems />} />
          <Route path="item/:id" element={<Item />} />
          <Route path="/policy" element={<TermofCondition />} />
          <Route path="/delivery" element={<OrderTracking />} />
          <Route path="/returnPolicy" element={<ReturnPolicy />} />
          <Route path="/payment" element={<PaymentMethods />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
