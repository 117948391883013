import React from "react";
import styled from "styled-components";

const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 18px;
  }
`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`;
const ProfileContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ProfileEdit = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
`;
const ItemSmallNotice = styled.div`
  background-color: #efeff0;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 20px;
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
`;
const ItemSmallNoticeSpan = styled.span`
  width: 100%;
  font-family: "Lora", serif;
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  display: block;
  &:last-child {
    margin-bottom: 36px;
  }
`;
const ItemSmallNoticeHead = styled.h3`
  font-size: 1.5rem;
  letter-spacing: -0.24px;
  line-height: 1.75rem;
  margin: 0px;
  margin-bottom: 24px;
`;
const DeleteMyAccount = () => {
  return (
    <ProfileWrapper>
      <ProfileWrapperTitle>Hesabın silinməsi</ProfileWrapperTitle>
      <ProfilePersonal>
        <ItemSmallNotice>
          <ItemSmallNoticeHead>
            Hesabınızı silmək üçün aşağıdakıları doğrulamalıyıq:
          </ItemSmallNoticeHead>
          <ItemSmallNoticeSpan>
            - Bütün ödənişlər və geri qaytarılmalar həll olunur.
          </ItemSmallNoticeSpan>
          <ItemSmallNoticeSpan>
            - Bütün hədiyyə kartı balansları və ya kreditləri tamamilə istifadə
            edilmişdir.
          </ItemSmallNoticeSpan>
          <ItemSmallNoticeSpan>
            - Bütün sifarişlər uğurla çatdırıldı.
          </ItemSmallNoticeSpan>
          Hesabınızı silməzdən əvvəl vacib sifariş təfərrüatlarını (zəmanət
          məlumatı kimi) yadda saxladığınızdan əmin olun. Gələcəkdə kömək üçün
          hər zaman bizimlə əlaqə saxlaya bilərsiniz, lakin bu məlumatı əldə edə
          bilməyəcəyik və onu təmin etməyiniz tələb olunacaq. Müştəri Xidmətləri
          ilə əvvəlki əlaqə də silinəcək.
        </ItemSmallNotice>
        <ProfileContent>
          <ProfileEdit>Hesabı və məlumatları Sil</ProfileEdit>
        </ProfileContent>
      </ProfilePersonal>
    </ProfileWrapper>
  );
};

export default DeleteMyAccount;
