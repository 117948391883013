import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { removeLike } from "../../redux/favoriteRedux";
const WishListWrapper = styled.section`
  min-height: 80vh;
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
  max-width: 80vw;
  margin: 24px auto;
  width: 100%;
`;
const ShoppingCart = styled.div`
  margin-bottom: 12px;
  padding: 22px;
  min-width: 100%;
`;
const ShoppingCartTitle = styled.h1`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 32px;
`;
const ShoppingCartList = styled.ul`
  padding: 0px;
  list-style: none;
`;
const ShoppingCartListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding: 24px 0px;
  border-bottom: 1px solid #f3f3f3;
  justify-content: space-between;
`;
const ShoppingCartListItemImg = styled.img`
  max-width: 40px;
  margin-right: 20px;
`;
const ShoppingCartListItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ShoppingCartListDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ShoppingCartListItemBtn = styled.button`
  max-width: 50px;
  cursor: pointer;
  height: 100%;
`;
const ShoppingCartListItemSpan = styled.span``;
const WishList = () => {
  const dispatch = useDispatch();
  const bol = useSelector((state) => state.favorite);
  const handleDelete = (p) => {
    dispatch(removeLike(p));
  };
  return (
    <WishListWrapper>
      <Main>
        <ShoppingCart>
          <ShoppingCartTitle>Black List</ShoppingCartTitle>
          <ShoppingCartList>
            {bol.likes?.map((p) => (
              <ShoppingCartListItem key={p.id}>
                <ShoppingCartListItemLink to={`/item/${p.id}`}>
                  <ShoppingCartListItemImg src={p.image} alt="" />
                  <ShoppingCartListItemSpan>
                    {p.itemSKU}&nbsp;-&nbsp;
                  </ShoppingCartListItemSpan>
                  <ShoppingCartListItemSpan>
                    {p.brand}&nbsp;-&nbsp;
                  </ShoppingCartListItemSpan>
                  <ShoppingCartListItemSpan>
                    {p.color}&nbsp;
                  </ShoppingCartListItemSpan>
                  <ShoppingCartListItemSpan>
                    {p.title && p.title}&nbsp;
                  </ShoppingCartListItemSpan>
                </ShoppingCartListItemLink>
                <ShoppingCartListDiv>
                  <ShoppingCartListItemBtn onClick={() => handleDelete(p)}>
                    Sil
                  </ShoppingCartListItemBtn>
                </ShoppingCartListDiv>
              </ShoppingCartListItem>
            ))}
          </ShoppingCartList>
        </ShoppingCart>
      </Main>
    </WishListWrapper>
  );
};

export default WishList;
