import React, { useState } from "react";
import styled from "styled-components";
const ItemAccordiontitle = styled.div`
  padding: 16px 10px;
  /* box-shadow: inset 0 1px 0 0 #d0d1d3, inset 0 -1px 0 0 #d0d1d3; */
  border-bottom: 1px solid #d0d1d3;
  display: flex;
  &:hover {
    background-color: #efeff0;
    cursor: pointer;
  }
  ::active{
    background-color:#efeff0;
  }
`;
const ItemAccordionItem = styled.div`

`;

const ItemAccordionDiv = styled.div`
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  width: 100%;
`;
const ItemAccordionContent = styled.div`
 font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding: 16px 10px;
`;
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <ItemAccordionItem>
      <ItemAccordiontitle onClick={() => setIsActive(!isActive)} active>
        <ItemAccordionDiv>{title}</ItemAccordionDiv>
        <svg
          height="1em"
          width="1em"
          focusable="false"
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M2.859 7.475a.75.75 0 0 1 1.06 0l7.55 7.55a.751.751 0 0 0 1.06 0l7.551-7.55a.75.75 0 1 1 1.061 1.06l-7.55 7.55a2.252 2.252 0 0 1-3.182 0l-7.55-7.55a.748.748 0 0 1 0-1.06z"></path>
        </svg>
      </ItemAccordiontitle>
      {isActive && <ItemAccordionContent>{content}</ItemAccordionContent>}
    </ItemAccordionItem>
  );
};

export default Accordion;
