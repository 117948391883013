import React from "react";
import styled from "styled-components";

const FooterPage = styled.div`
  display: flex;
  flex-direction: column;
`;
const FooterPageContent = styled.div`
  width: 80vw;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  position: relative;
`;
const PageTitle = styled.h1``;
const FooterPageTextBlock = styled.div``;
const FooterPageTextContent = styled.p``;
const TermofCondition = () => {
  return (
    <FooterPage>
      <FooterPageContent>
        <PageTitle>İstifadəçi Şərtləri</PageTitle>
        <FooterPageTextBlock>
          <FooterPageTextContent>
            <p>
              BOGUCCI saytında onlayn olaraq verilən sifarişlər üçün Standart
              Şərtlər.
            </p>
            <p>
              Satınalmalarınızı necə emal etdiyimizi və hesablaşmamızı
              tənzimləyən standart şərtlərimizlə sizi aşağıda tanış etmək
              istərdik.
            </p>
            <p>
              www.boguccifashion.com vebsaytımızdakı təkliflər hər biri tam
              original və authentic məhsullardır. Məhsullar hər biri rəsmi
              istehsalçıdan və ya filiallarından göndərilir.
            </p>
            <p>1. Müqavilənin qurulması və malların çatdırılması</p>
            <p>
              1.1 Sifariş verməklə siz yalnız şəxsi məqsədlər üçün satın
              aldığınızı təsdiq edirsiniz. Bu səsəbdən hər bir maldan bir ədəd
              sifariş edə bilərsiniz.
            </p>
            <p>
              1.2 boguccifashion.com saytında onlayn olaraq verilən sifarişlər
              üçün biz yalnız alman və ya ingilis dilində müqavilələr
              bağlayırıq. Veb saytın başlığında dil seçicisindən istifadə
              etməklə və ya mobil Tətbiqdə Hesabım bölməsinə keçməklə
              istədiyiniz dili seçə bilərsiniz.
            </p>
            <p>
              1.3 Malların təsviri materialı və ölçüləri haqqında malın
              səhifəsindən tanış ola bilərsiniz
            </p>
            <p>
              1.4 "Ödə" üzərinə klikləməklə, siz alış-veriş səbətinizdəki
              əşyalar üçün məcburi sifariş verirsiniz. Bu şifarişin qeydə
              alınması deyil. Sifarişin qeydə alınması üçün sifariş kodunu
              (Sifariş səbətinizdə sağ hissədən və ya Hesab. Sifarişlər
              bölməsindən əldə edə bilərsiniz. Sifarişin təsdiqlənməsi sifariş
              kodunu və məbləği ödədikdən sonra qeydə alınır və Hesab.Sifarişlər
              bölməsində təsdiqlənməsi barədə məlumat qeyd olunur.
            </p>
            <p>
              1.4.1 Bizdən sifariş təsdiqini aldığınız zaman məcburi müqavilə
              bağlanır. Nəzərə alın ki, qabaqcıl ödənişlə sifariş edilən
              məhsullar (rezervasyonda) tam alış qiymətinin və istənilən
              çatdırılma xərclərinin ödənilməsini aldıqdan sonra göndəriləcək.
              Buna görə də sifarişin təsdiqini aldıqdan dərhal sonra, lakin ən
              azı 7 gün ərzində alış qiymətini köçürməyinizi xahiş edirik.
            </p>
            <p>
              1.5 Sifarişin dəyəri 500 AZN dən çox olarsa qabaqcadan ödəniş
              etməklə sifariş edə bilərsiniz.
            </p>
            <p>
              1.6 Nəzərə alın ki, istisna olaraq, bizim tədarükçülərimiz anbarı
              düzgün və ya vaxtında çatdıra bilmədikdə çatdırılma gecikərsə
              sifarişinizi qeyd edilən vaxtda çatdırmaq öhdəliyimiz yoxdur,
              baxmayaraq ki, bizim tərəfimizdən müvafiq sifariş praktikasına
              riayət edirik (uyğun alış əməliyyatı (kongruentes
              Deckungsgeschäft/ congruent hedging transaction/ opération de
              couverture congruente). Bu istisnanın tətbiqi üçün biz malın
              əlçatmaz olmasına görə qanuni məsuliyyət daşımamalı və lazımsız
              gecikmə olmadan vəziyyət barədə sizi xəbərdar etməliyik.Bundan
              əlavə, sifariş edilən mallarla bağlı satınalma riskini öz üzərinə
              götürməməliyik. mal mövcud deyil, biz sizin tərəfinizdən edilən
              hər hansı ödənişi lazımsız gecikmə olmadan geri qaytaracağıq.
            </p>
            <p>
              1.7 Biz sifarişli malları başqa yerdən almaq riskini öz üzərimizə
              götürmürük (satınalma riski). Bu, generik mallar üçün sifarişlərə
              də aiddir (burada yalnız malların növü və xüsusiyyətləri təsvir
              olunur). Biz yalnız mövcud ehtiyatlarımızdan və
              təchizatçılarımızdan sifariş etdiyimiz ehtiyatlardan tədarük
              etməyə borcluyuq. Vaxt dövrlərinin iş günləri ilə ifadə edildiyi
              hallarda biz iş günlərini bayram günləri deyil, bazar ertəsi və
              cümə günləri daxil olmaqla bütün günləri başa düşürük.
            </p>
            <p>
              1.8 Biz hər bir məhsulun təfərrüatlı səhifəsində, eləcə də sifariş
              verməzdən əvvəl sizə çatdırılma vaxtı barədə məlumat veririk.
            </p>
            <p>
              1.9 Sifariş verdikdə hesab məlumatlarında ünvan,telefon, ad soyad
              yalnız olarsaş Bu səbəbdən sifarişiniz çatırılmazsa bu halda
              sifarişi qəbul etməmək və ya sifarişi ləğv etmək hüququmuzu
              özümüzdə saxlayırıq. Və sifariş məbləği geri qaytarilmır. sizin
              adınıza sifariş verin.
            </p>

            <p>2. Qiymətlər, çatdırılma xərcləri</p>
            <p>
              2.1 Sifarişin verildiyi tarixdə təklifdə göstərilən qiymətlər
              tətbiq edilir. Göstərilən qiymətlər yekun qiymətlərdir (cəmidir),
              başqa sözlə, onlara qüvvədə olan qanunla müəyyən edilmiş dərəcə
              ilə göndərilən ölkənin əlavə dəyər vergisi daxildir. Alış qiyməti
              tam ödənilənə qədər mal bizim və ya müvafiq Zalando tərəfdaşının
              mülkiyyəti olaraq qalır.
            </p>
            <p>2.2 Çatdırılma tam ödənişsizdir.</p>

            <p>3.Geri qaytarıma şərtləri</p>
            <p>
              3.1 Sifarişi aldıqdan sonra məhsul(lar)dan narazı olarsınızsa
              məhsulun ƏDV xərci çıxılmaqla məhsulu geri qaytara bilərsiniz.
            </p>
            <p>
              3.2 Məhsulun saytda qeyd olunmayan təsvir və məlumatlarından
              narazı olduğunuz halda geri qaytarılma etmək mümkündür.{" "}
            </p>
          </FooterPageTextContent>
        </FooterPageTextBlock>
      </FooterPageContent>
    </FooterPage>
  );
};

export default TermofCondition;
