import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";

const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 18px;
  }
`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProfileContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
const ProfileEdit = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const ProfileLabel = styled.div`
  flex: 1;
  min-width: 33.333%;
  flex-shrink: 3;
  overflow: hidden;
`;
const ProfileInfoLabel = styled.p`
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  &:nth-child(even) {
    background: red;
  }
`;
const ProfileInfoData = styled.p`
  font-size: 1rem;
  letter-spacing: -0.16px;
  line-height: 1.5rem;
  padding: 0px;
  margin: 0px 0px 24px;
  @media only screen and (max-width: 480px) {
    margin: 0px 0px 10px;
    background: #cccccc29;
    font-size: 14px;
    padding: 5px;
  }
`;
const PersonalDetalis = () => {
  // const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const { user } = useSelector((state) => state.user);

  let navigate = useNavigate();

  useEffect(() => {
    getUserPosts();
  }, []);

  const getUserPosts = async () => {
    try {
      // Create a query against the collection.
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setUserData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {}
  };
  const handleEdit = () => {
    navigate("/profile/edit");
  };
  return (
    <ProfileWrapper>
      <ProfileWrapperTitle>Şəxsi məlumatlar</ProfileWrapperTitle>
      <ProfilePersonal>
        <ProfileContent>
          <ProfileLabel>
            <ProfileInfoLabel>İstifadəçi adı:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.fullname}</ProfileInfoData>
          </ProfileLabel>
          <ProfileLabel>
            <ProfileInfoLabel>Telefon:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.phone}</ProfileInfoData>
          </ProfileLabel>
          {/* <ProfileLabel>
            <ProfileInfoLabel>Seçilmiş Moda:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.fashion}</ProfileInfoData>
          </ProfileLabel> */}
          <ProfileLabel>
            <ProfileInfoLabel>Doğum tarixi:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.birth}</ProfileInfoData>
          </ProfileLabel>
          <ProfileLabel>
            <ProfileInfoLabel>Email:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.email}</ProfileInfoData>
          </ProfileLabel>
          <ProfileLabel>
            <ProfileInfoLabel>Ünvan:</ProfileInfoLabel>
            <ProfileInfoData>{userData?.address}</ProfileInfoData>
          </ProfileLabel>
        </ProfileContent>
        <ProfileEdit onClick={handleEdit}>Dəyişdir</ProfileEdit>
      </ProfilePersonal>
    </ProfileWrapper>
  );
};

export default PersonalDetalis;
