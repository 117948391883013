import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeFashion } from "../../redux/fashionRedux";
const Container = styled.div`
  max-width: 100vw;
  margin: 0px auto;
  padding: 0px 20px;
  display: flex;
  border-bottom: 1px solid #ddd;
  @media only screen and (max-width: 480px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 16px;
  }
`;
const Left = styled.div`
  flex: 1;
  display: flex;

  @media only screen and (max-width: 480px) {
    order: 3;
    flex: 50%;
    border-top: 1px solid #ddd;
  }
`;
const NavCategory = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: start;
  @media only screen and (max-width: 480px) {
    justify-content: space-between;
  }
`;
const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(33, 42, 47);
  @media only screen and (max-width: 480px) {
    order: 1;
    width: 50%;
    padding: 0px;
    margin: 0px;
    align-items: center;
    justify-content: start;
  }
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;

  @media only screen and (max-width: 480px) {
    order: 2;
    width: 50%;
    padding: 0px;
    margin: 0px;
    align-items: center;
    justify-content: end;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
`;
const Menu = styled.ul`
  display: flex;
  @media only screen and (max-width: 480px) {
    margin: 0px;
  }
`;
const MenuItem = styled.li`
  list-style: none;
  margin-right: 10px;

  @media only screen and (max-width: 480px) {
    &:last-child {
      margin-right: 0px;
    }
  }
`;
const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: #1a1a1a;
  line-height: 24px;
  padding: 10px;
  text-decoration: none;
  display: block;
  position: relative;
  cursor: pointer;
  &.active {
    background-color: #222;
    color: #fff;
  }
`;
const Badge = styled.span`
  background: #ff6900;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  color: #fff;
  top: -3px;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
  margin-top: 0px;
  right: -5px;
  line-height: 20px;
  font-size: 10px;
`;
const LogoText = styled.h1`
  color: rgb(26, 26, 26);

  @media only screen and (max-width: 480px) {
    margin: 12px 0px;
    font-size: 24px;
  }
`;

export const Header = () => {
  // const { currentUser } = useContext(AuthContext);
  const [selectFashion, setSelectFashion] = useState("Women");
  const dispatch = useDispatch();
  const quantity = useSelector((state) => state.cart.products);
  const likes = useSelector((state) => state.favorite.likes);
  const { user } = useSelector((state) => state.user);

  dispatch(changeFashion(selectFashion));
  return (
    <Container>
      <Left>
        <NavCategory>
          <MenuItemLink
            as={NavLink}
            to="items/woman"
            state={{ uri: encodeURI("Women"), title: "Qadın" }}
            activeclassname="active"
            onClick={() => setSelectFashion("Women")}
          >
            Qadın
          </MenuItemLink>
          <MenuItemLink
            as={NavLink}
            to="items/men"
            state={{ uri: encodeURI("Men"), title: "Kişi" }}
            activeclassname="active"
            onClick={() => setSelectFashion("Men")}
          >
            Kişi
          </MenuItemLink>
          <MenuItemLink
            as={NavLink}
            to="items/kids"
            state={{ uri: encodeURI("Kids"), title: "Uşaq" }}
            activeclassname="active"
            onClick={() => setSelectFashion("Kids")}
          >
            Uşaq
          </MenuItemLink>
        </NavCategory>
      </Left>
      <Center>
        <Link to="/">
          <LogoText>BOGUCCI</LogoText>
        </Link>
      </Center>
      <Right>
        <Nav>
          <Menu>
            <MenuItem>
              {user ? (
                <MenuItemLink to="profile/personal" style={{ padding: "8px" }}>
                  {/* {user.unPaidCount.length > 0 && <Badge>{user.unPaidCount.length}</Badge>} */}

                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      alt={user.displayName}
                      style={{
                        width: "25px",
                        borderRadius: "50%",
                        height: "25px",
                      }}
                      title={user.displayName}
                    />
                  ) : (
                    <svg
                      height="20px"
                      width="20px"
                      focusable="false"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-labelledby="login-102032135"
                      role="img"
                      aria-hidden="false"
                    >
                      <path d="M21.645 22.866a28.717 28.717 0 0 0-6.46-7.817c-2.322-1.892-4.048-1.892-6.37 0a28.74 28.74 0 0 0-6.46 7.817.75.75 0 0 0 1.294.76 27.264 27.264 0 0 1 6.113-7.413A3.98 3.98 0 0 1 12 15.125a3.81 3.81 0 0 1 2.236 1.088 27.252 27.252 0 0 1 6.115 7.412.75.75 0 1 0 1.294-.76zM12 12.002A6.01 6.01 0 0 0 18.003 6 6.003 6.003 0 1 0 12 12.002zm0-10.505a4.502 4.502 0 1 1 0 9.005 4.502 4.502 0 0 1 0-9.005z"></path>
                    </svg>
                  )}
                </MenuItemLink>
              ) : (
                <MenuItemLink to="login">
                  <svg
                    height="20px"
                    width="20px"
                    focusable="false"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-labelledby="login-102032135"
                    role="img"
                    aria-hidden="false"
                  >
                    <path d="M21.645 22.866a28.717 28.717 0 0 0-6.46-7.817c-2.322-1.892-4.048-1.892-6.37 0a28.74 28.74 0 0 0-6.46 7.817.75.75 0 0 0 1.294.76 27.264 27.264 0 0 1 6.113-7.413A3.98 3.98 0 0 1 12 15.125a3.81 3.81 0 0 1 2.236 1.088 27.252 27.252 0 0 1 6.115 7.412.75.75 0 1 0 1.294-.76zM12 12.002A6.01 6.01 0 0 0 18.003 6 6.003 6.003 0 1 0 12 12.002zm0-10.505a4.502 4.502 0 1 1 0 9.005 4.502 4.502 0 0 1 0-9.005z"></path>
                  </svg>
                </MenuItemLink>
              )}
            </MenuItem>
            <MenuItem>
              <MenuItemLink to="/wishlist">
                <svg
                  height="20px"
                  width="20px"
                  focusable="false"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-hidden="false"
                >
                  <title id="wish-list-102032136">Wish list</title>
                  <path d="M17.488 1.11h-.146a6.552 6.552 0 0 0-5.35 2.81A6.57 6.57 0 0 0 6.62 1.116 6.406 6.406 0 0 0 .09 7.428c0 7.672 11.028 15.028 11.497 15.338a.745.745 0 0 0 .826 0c.47-.31 11.496-7.666 11.496-15.351a6.432 6.432 0 0 0-6.42-6.306zM12 21.228C10.018 19.83 1.59 13.525 1.59 7.442c.05-2.68 2.246-4.826 4.934-4.826h.088c2.058-.005 3.93 1.251 4.684 3.155.226.572 1.168.572 1.394 0 .755-1.907 2.677-3.17 4.69-3.16h.02c2.7-.069 4.96 2.118 5.01 4.817 0 6.089-8.429 12.401-10.41 13.8z"></path>
                </svg>
                {likes?.length > 0 && <Badge>{likes?.length}</Badge>}
              </MenuItemLink>
            </MenuItem>
            <MenuItem>
              <MenuItemLink to="/basket">
                {quantity.length > 0 ? (
                  <svg
                    height="20px"
                    width="20px"
                    focusable="false"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-labelledby="your-bag-102032137"
                    role="img"
                    aria-hidden="false"
                  >
                    <path d="M20.677 13.654a5.23 5.23 0 0 0 1.073-3.194c-.01-2.632-1.968-4.78-4.5-5.137V5.25a5.25 5.25 0 0 0-10.5 0v.059a5.224 5.224 0 0 0-2.444 1.014 5.23 5.23 0 0 0-.983 7.33A5.623 5.623 0 0 0 6.375 24h11.25a5.623 5.623 0 0 0 3.052-10.346zM12 1.5a3.75 3.75 0 0 1 3.75 3.75h-7.5A3.75 3.75 0 0 1 12 1.5zm5.625 21H6.375a4.122 4.122 0 0 1-1.554-7.942.75.75 0 0 0 .214-1.256A3.697 3.697 0 0 1 3.75 10.5a3.755 3.755 0 0 1 3-3.674V9a.75.75 0 0 0 1.5 0V6.75h7.5V9a.75.75 0 1 0 1.5 0V6.826a3.755 3.755 0 0 1 3 3.674c0 1.076-.47 2.1-1.285 2.802a.75.75 0 0 0 .213 1.256 4.122 4.122 0 0 1-1.553 7.942z"></path>
                  </svg>
                ) : (
                  <svg
                    className="zds-icon  X9n9TI DlJ4rT _5Yd-hZ pVrzNP H3jvU7"
                    height="20px"
                    width="20px"
                    focusable="false"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    role="img"
                    aria-hidden="false"
                  >
                    <title id="your-bag-62772524">Your bag</title>
                    <path d="M21.193 8.712a2.984 2.984 0 0 0-2.986-2.726h-.952v-.751a5.255 5.255 0 0 0-10.51 0v.75h-.951a2.983 2.983 0 0 0-2.986 2.727L1.715 20.73A2.999 2.999 0 0 0 4.7 24h.005l14.599-.027a2.998 2.998 0 0 0 2.98-3.27L21.193 8.712zM8.246 5.235a3.754 3.754 0 0 1 7.508 0v.75H8.246v-.75zm11.056 17.238-14.599.025h-.002a1.496 1.496 0 0 1-1.49-1.631l1.093-12.02a1.488 1.488 0 0 1 1.49-1.36h.95V9.74a.75.75 0 0 0 1.502 0V7.487h7.508V9.74c0 .415.336.75.75.75h.002a.75.75 0 0 0 .75-.75V7.487h.951a1.49 1.49 0 0 1 1.49 1.361l1.092 11.993a1.496 1.496 0 0 1-1.488 1.632z"></path>
                  </svg>
                )}
                {quantity.length > 0 && <Badge>{quantity.length}</Badge>}
              </MenuItemLink>
            </MenuItem>
          </Menu>
        </Nav>
      </Right>
    </Container>
  );
};
