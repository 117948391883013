import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { query, where, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import Card from "../../componenets/Card/Card";
import { useSelector } from "react-redux";
const Container = styled.div`
  max-width: 80vw;
  margin: 0px auto;
  padding: 0px 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    max-width: 100vw;
    padding: 0px 0px;
  }
`;
const Breadcrumbs = styled.div``;
const BreadcrumbsList = styled.ul`
  display: flex;
  margin: 20px 10px 10px;
  padding: 0px;
  justify-content: start;
  align-items: start;
  width: fit-content;
`;
const BreadcrumbsListItem = styled.li`
  display: flex;
  /* align-items: center; */
  justify-content: start;
  flex: 1;
  margin-right: 10px;
  font-weight: 400;
  min-width: fit-content;
  &:last-child {
    font-weight: 100;
  }
`;
const BreadcrumbsListItemLink = styled.a`
  margin: 0px;
  padding: 0px;
  line-height: 11px;
  margin-right: 10px;
`;
const PageTitle = styled.h1`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  color: #1a1a1a;
  margin: 20px 10px 10px;
  display: flex;
`;
const Main = styled.main`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;
// const Left = styled.aside`
//   flex: 1 3;
// `;
// const Right = styled.section`
//   flex: 1;
//   width: 100%;
// `;
const RightFilterWrapper = styled.div``;
const RightItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Item = styled.div`
  flex-basis: 25%;
  max-width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px 0 24px;
  @media only screen and (max-width: 480px) {
    flex-basis: 50%;
    max-width: 50%;
    .cartBox {
      position:relative ;
    }
  }
`;
const Subitems = () => {
  let location = useLocation();
  let str = location.pathname.split("/")[2]; //fashion
  // let strValue = location.pathname.split("/")[3]; //altCategory or brand
  let state = location.state;

  const [data, setData] = useState([]);
  // const [fashion] = useContext(FashionContext);
  const fashion = useSelector((state) => state.fashion.selectedFashion);

  useEffect(() => {
    fetchData(fashion, state);
  }, [state, fashion]);

  const fetchData = async (str, state) => {
    try {
      // let _str = await str.charAt(0).toUpperCase() + str.slice(1)
      let _catItems = [];
      const citiesRef = collection(db, "products");
      if (state.byWhat === "altCategory") {
        const q = query(
          citiesRef,
          where("isPublished", "==", true),
          where("mainCategory", "==", str),
          where("altCategory", "==", state.title)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _catItems.push({ id: doc.id, ...doc.data() });
        });
        setData(_catItems);
      } else {
        const q = query(
          citiesRef,
          where("isPublished", "==", true),
          where("mainCategory", "==", str),
          where("brand", "==", state.desc)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _catItems.push({ id: doc.id, ...doc.data() });
        });
        setData(_catItems);
      }
    } catch (error) {
      console.log(error.code);
    }
  };
  return (
    <Container>
      <Breadcrumbs>
        <BreadcrumbsList>
          <BreadcrumbsListItem>
            <BreadcrumbsListItemLink to={`/items/${fashion}`}>
              {str.charAt(0).toUpperCase() + str.slice(1)}
            </BreadcrumbsListItemLink>
            <svg
              style={{
                width: "1rem",
                height: "1rem",
                fontSize: "1rem",
                letterSpacing: "-.16px",
                lineHeight: "1.5rem",
              }}
              focusable="false"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M7.63 21.14a.75.75 0 0 1 0-1.06l7.55-7.55a.751.751 0 0 0 0-1.06L7.63 3.92a.75.75 0 1 1 1.06-1.061l7.55 7.55a2.252 2.252 0 0 1 0 3.182l-7.55 7.55a.748.748 0 0 1-1.06 0z"></path>
            </svg>
          </BreadcrumbsListItem>
          <BreadcrumbsListItem>
            <BreadcrumbsListItemLink>
              {state && state.desc}
            </BreadcrumbsListItemLink>
          </BreadcrumbsListItem>
        </BreadcrumbsList>
      </Breadcrumbs>
      <PageTitle>
        {state && state.translate}
      </PageTitle>
      <Main>
       {data?.length === 0 && <p>Bu kateqoriyada mal yoxdur</p>}
        <RightFilterWrapper></RightFilterWrapper>
        <RightItemWrapper>
          {data ? (
            data?.map((p) => (
              <Item key={p.id}>
                <Card props={p} key={p.id} />
              </Item>
            ))
          ) : (
            <p>Item not found</p>
          )}
        </RightItemWrapper>
      </Main>
    </Container>
  );
};

export default Subitems;
