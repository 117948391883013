import { configureStore } from "@reduxjs/toolkit";
import cartReducer from './cartRedux';
import fashionRedux from "./fashionRedux";
import favoriteRedux from "./favoriteRedux";
import userRedux from "./userRedux";

export default configureStore({
    reducer:{
        cart: cartReducer,
        favorite: favoriteRedux,
        fashion: fashionRedux,
        user: userRedux
    }
})