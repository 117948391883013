import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Input = styled.input`
  min-width: 30vw;
  padding: 8px;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  input[type="date"],
  input[type="time"] {
    -webkit-appearance: none;
  }
`;
const Label = styled.label`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  justify-content: start;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: baseline;
  }
`;
const ProfileEdit = styled.button`
  font-size: 14px;
  padding: 13px 18px;
  cursor: pointer;
  transition: all 100ms ease 0s;
  letter-spacing: 2px;
  background-color: rgb(33, 42, 47);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(33, 42, 47);
  text-align: center;
  text-decoration: none;
  opacity: 1;
  position: relative;
  border-radius: 2px;
  font-weight: 700;
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const LabelText = styled.span`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: start;
`;
// const LabelSelect = styled.select`
//   min-width: 30vw;
//   padding: 8px;
// `;
const Edit = () => {
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState({});
  const { user } = useSelector((state) => state.user);
  // const { dispatch } = useContext(FashionContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData(user.uid);
  }, [user.uid]);

  const fetchUserData = async (id) => {
    try {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      setUserData(docSnap.data());
    } catch (error) {}
  };

  const handleUser = async () => {
    // dispatch(update({...data}));
    try {
      await updateDoc(doc(db, "users", user.uid), {
        ...data,
      });
      navigate("/profile/personal");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  return (
    <div>
      <Label>
        <LabelText> Ad Soyad:</LabelText>
        <Input
          type="text"
          onChange={handleInput}
          id="fullname"
          placeholder={userData.fullname}
        />
      </Label>
      <Label>
        <LabelText> Telefon:</LabelText>
        <Input
          type="tel"
          onChange={handleInput}
          id="phone"
          placeholder={userData.phone}
        />
      </Label>
      <Label>
        <LabelText> Dogum Tarixi:</LabelText>
        <Input type="date" onChange={handleInput} id="birth" />
      </Label>
      <Label>
        <LabelText> Ünvan:</LabelText>
        <Input
          type="text"
          onChange={handleInput}
          id="address"
          placeholder={userData.address}
        />
      </Label>
      <ProfileEdit onClick={handleUser}>Yadda Saxla</ProfileEdit>
    </div>
  );
};

export default Edit;
