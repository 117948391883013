import React from "react";
import styled from "styled-components";

const ProfileWrapper = styled.div``;
const ProfileWrapperTitle = styled.h3`
  font-size: 2.5rem;
  letter-spacing: -0.4px;
  line-height: 3rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 48px;
`;
const ProfilePersonal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`;
const ProfileContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const OrdersTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 24px;
`;
const OrdersTableTr = styled.tr`
  border-bottom: 1px solid #212a2f;

  &:hover td {
    background-color: #212a2f1a;
  }
`;
const OrdersTableThead = styled.thead``;
const OrdersTableTbody = styled.tbody``;
const OrdersTableTh = styled.th`
  padding: 10px;
  background-color: #212a2f;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
`;
const OrdersTableTd = styled.td`
  padding: 10px;
  color: #212a2f;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  text-align: center;
`;
const OrderTableImg = styled.img`
  max-width: 50px;
  max-height: 80px;
  object-fit: cover;
`;
const MySizes = () => {
  return (
    <ProfileWrapper>
      <ProfileWrapperTitle>Mənim ölçülərim</ProfileWrapperTitle>
      <ProfilePersonal>
        <ProfileContent>
          <OrdersTable>
            <OrdersTableThead>
              <OrdersTableTr>
                <OrdersTableTh>#</OrdersTableTh>
                <OrdersTableTh>Malın tipi</OrdersTableTh>
                <OrdersTableTh>Brend</OrdersTableTh>
                <OrdersTableTh>Ölçü</OrdersTableTh>
              </OrdersTableTr>
            </OrdersTableThead>
            <OrdersTableTbody>
              <OrdersTableTr>
                <OrdersTableTd>1Afd5A</OrdersTableTd>
                <OrdersTableTd>ayaqqabi</OrdersTableTd>
                <OrdersTableTd>31.1.2023</OrdersTableTd>
                <OrdersTableTd>Jack and Jhones</OrdersTableTd>
              </OrdersTableTr>
              <OrdersTableTr>
                <OrdersTableTd>1Afd5A</OrdersTableTd>
                <OrdersTableTd>Kostyum</OrdersTableTd>
                <OrdersTableTd>31.1.2023</OrdersTableTd>
                <OrdersTableTd>Jack and Jhones</OrdersTableTd>
              </OrdersTableTr>
            </OrdersTableTbody>
          </OrdersTable>
        </ProfileContent>
      </ProfilePersonal>
    </ProfileWrapper>
  );
};

export default MySizes;
